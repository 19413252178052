import React, { memo, useEffect, useState } from "react";
import "./ShapeUpdateForm.css";

import { PiKanban } from "react-icons/pi";

import { useNodeContext } from "../../../context/NodeContext";
import FormBuilderOverlay from "../../../template/form_builder_overlay/FormBuilderOverlay";
import { useFormContext } from "../../../context/FormContext";
import { useProcessContext } from "../../../context/ProcessContext";
import BASE_URL, { API_MIDPOINT, PAYLOAD_SECRET } from "../../../../config";
import SelectedForm from "../../../template/selected_form_ui/SelectedForm";
import { MdDelete, MdEditNote, MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import SelectedKanban from "../../../template/selected_form_ui/SelectedKanban";
import ConditionalModalOverlay from "../../../template/decision_conditional_modal/ConditionalModalOverlay";
import { IoMdClose } from "react-icons/io";
import SelectField from "../../../template/selection_field/SelectField";
import { GiCancel } from "react-icons/gi";
import ConditionFields from "../../../template/condition_fields/ConditionFields";
import ConditionContainer from "../../../template/condition_fields/ConditionContainer";
import DoctypeForm from "../../../template/doctype_form/DoctypeForm";
import CustomDropdown from "../../../template/custom_dropdown/CustomDropdown";
import CreateCustomer from "./CreateCustomer";
import ActionComponent from "./ActionComponent";
import CreateVendor from "./CreateVendor";
import CreateInvoice from "./CreateInvoice";
import CustomerCreateOverlay from "../../../template/action_modals/CustomerCreateOverlay";
import VendorCreateOverlay from "../../../template/action_modals/VendorCreateOverlay";
import InvoiceCreateOverlay from "../../../template/action_modals/InvoiceCreateOverlay";
import Swal from "sweetalert2";
import { FaDeleteLeft, FaEye, FaEyeSlash } from "react-icons/fa6";
import EmailTemplateOverlay from "../../../template/email_template_modal/EmailTemplateOverlay";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import ExportFormFieldJson from "../../../utility_files/ExportFormFieldJson";
import SelectEmailTemplate from "../../../template/selection_field/SelectEmailTemplate";
import DefaultTab from "./properties_tabs_pages/DefaultTab";

const ShapeUpdateForm = () => {
  const navigate = useNavigate();
  const { nodes, setNodes, setShowShapeUpdateForm, shapeId, updateNodeTitle } =
    useNodeContext();
  const {
    forms,
    setForms,
    newFormId,
    setKanbanDetail,
    setIsKanbanBuilder,
    fetchForms,
    parseFormData,
  } = useFormContext();
  const {
    newProcessId,
    userData,
    setToDos,
    setDocTypes,
    groups,
    topRightAlert,
    processDetail,
    fetchAllGroups,
    centerAlert,
    emailTemplateId,
    emailTemplates,
    setEmailTemplates,
    setEmailTemplateId,
    setEmailTemplateModalOpen,
    isUpdateEmailTemplate,
    setUpdateEmailTemplate,
    fetchAllEmailTemplates,
    encryptData,
  } = useProcessContext();
  const [conditionModalOpen, setConditionModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState("General");
  const [showApiEnableUrl, setShowApiEnableUrl] = useState(false);
  const [showDocTypeForm, setShowDocTypeForm] = useState(false);
  const [doctypes, setDoctpes] = useState([]);
  const tabs = [
    "General",
    "Default",
    "Views",
    "Template",
    "Advance",
    "Steps",
    "Action",
    "DocTypes",
    "ToDos",
  ];

  const [shapeData, setShapeData] = useState({
    value: "",
    type: "",
    group: [],
    action: "",
    form: "",
    isFormSelected: false,
    kanbanData: null,
    prev: null,
    prev_name: null,
    current: null,
    next: null,
    next_name: null,
    elseNextStep: null,
    // isWebForm: false,
    isApiEnable: false,
    isPreCondition: false,
    preCondition: null,
    isPostCondition: false,
    postCondition: null,
    doctype: null,
    todos: null,
  });

  // console.log("newFormId: ", newFormId);
  console.log("newProcessId: ", newProcessId);

  // hard coded doctype object for testing
  // const doctypes = [
  //   {
  //     docType_type: "pdf",
  //     docType_description: "this is portable document format",
  //     isMandatory: true,
  //   },
  //   {
  //     docType_type: "docx",
  //     docType_description: "this is text file",
  //     isMandatory: false,
  //   },
  //   {
  //     docType_type: "pptx",
  //     docType_description: "this is power point file",
  //     isMandatory: true,
  //   },
  // ];

  // Set shape data from the selected node
  useEffect(() => {
    if (shapeId !== null && nodes) {
      const selectedNode = nodes.find((node) => node.id === shapeId);
      console.log("selectedNode::::: ", selectedNode);
      if (selectedNode) {
        const nextsteps = selectedNode.data.next
          ? selectedNode.data.next
          : selectedNode.data.decision_conditions
          ? selectedNode.data.decision_conditions.map((condition) => ({
              condition,
              nextStep: "",
            }))
          : selectedNode.data.next;

        // console.log("nextSteps: ", nextsteps);
        if (["decision", "email", "email_verify"].includes(selectedNode.type)) {
          if (["Views", "Advance"].includes(activeTab)) {
            setActiveTab("General");
          }
        }
        if (selectedNode.type !== "email") {
          if (activeTab === "Template") {
            setActiveTab("General");
          }
        }
        if (selectedNode.type !== "start") {
          if (activeTab === "Advance") {
            setActiveTab("General");
          }
        }
        if (selectedNode.type === "email") {
          shapeData.template = selectedNode.data.template || null;
          shapeData.emailTo = selectedNode.data.emailTo || "";
          setEmailTemplateId(selectedNode?.data?.template?.template_id || null);
        }
        setShapeData({
          ...shapeData,
          value: selectedNode.data.value || "",
          type: selectedNode.type || "",
          group: selectedNode.data.group || [],
          action: selectedNode.data.action || "",
          form: selectedNode.data.form || "",
          isFormSelected: selectedNode.data.isFormSelected || false,
          kanbanData: selectedNode.data.kanbanData || null,
          prev: selectedNode.data.prev || null,
          prev_name: selectedNode.data.prev_name || null,
          current: selectedNode.data.current || null,
          next: nextsteps || null,
          next_name: selectedNode.data.next_name || null,
          elseNextStep: selectedNode.data.elseNextStep || null,
          // isWebForm: selectedNode.data.isWebform || false,
          isApiEnable: selectedNode.data.isApiEnable || false,
          isPreCondition: selectedNode.data.isPreCondition || false,
          preCondition: selectedNode.data.preCondition
            ? selectedNode.data.preCondition
            : null,
          isPostCondition: selectedNode.data.isPostCondition || false,
          postCondition: selectedNode.data.postCondition
            ? selectedNode.data.postCondition
            : null,
        });
      }
    }
    console.log("shapeData: ", shapeData);
  }, [shapeId, nodes]);

  // Handling input data for shape details
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleStepsChange = (e) => {
    const { name, value } = e.target;
    if (value !== "" || value !== undefined) {
      const stepNode = nodes.find((node) => value === node.id);
      const nodeName = stepNode.data.value;
      const stepKey = `${name}_name`;
      setShapeData((prevShapeData) => ({
        ...prevShapeData,
        [name]: value,
        [stepKey]: nodeName,
      }));
    }
  };
  const handleEmailToChange = (e) => {
    if (
      e.target.value !== "" &&
      e.target.value !== null &&
      e.target.value !== undefined
    ) {
      setShapeData((prevData) => ({ ...prevData, emailTo: e.target.value }));
    }
  };

  // handling email template
  const [emailToFields, setEmailToFields] = useState(null);
  // method for remove template
  const handleTemplateRemove = async (e, templateId) => {
    e.preventDefault();
    console.log("email temp::: templateId::::: ", templateId);
    // =================================
    // working code
    // try {
    //   let templateNodeCount = 0;
    //   nodes.forEach((node) => {
    //     if (
    //       node.data.template &&
    //       node.data.template.template_id === templateId
    //     ) {
    //       templateNodeCount++;
    //     }
    //   });
    //   console.log("templateNodeCount::: ", templateNodeCount);
    //   if (templateNodeCount === 1) {
    //     const response = await fetch(`${BASE_URL}/email/delete`, {
    //       method: "Post",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         email_id: templateId,
    //         user_id: userData.userid,
    //         key: API_KEY,
    //       }),
    //     });
    //     if (response.ok) {
    //       const data = await response.json();
    //       setNodes((prevNodes) =>
    //         prevNodes.map((node) =>
    //           node.id === shapeId && node.data.template
    //             ? {
    //                 ...node,
    //                 data: {
    //                   ...node.data,
    //                   ...shapeData,
    //                   template: null,
    //                 },
    //               }
    //             : node
    //         )
    //       );
    //       setEmailTemplateId(null);
    //       console.log("email template deleted response: ", data);
    //       topRightAlert("success", data.message);
    //     } else {
    //       const data = await response.json();
    //       console.log("error in deleting email template: ", data.message);
    //       centerAlert("error", data.message);
    //     }
    //   } else {
    //     setNodes((prevNodes) =>
    //       prevNodes.map((node) =>
    //         node.id === shapeId
    //           ? {
    //               ...node,
    //               data: {
    //                 ...node.data,
    //                 ...shapeData,
    //                 template: null,
    //               },
    //             }
    //           : node
    //       )
    //     );
    //     setEmailTemplateId(null);
    //     topRightAlert("success", "template removed from this activity.");
    //   }
    // } catch (error) {
    //   console.log("error in form delete: ", error);
    //   centerAlert("error", error);
    // }
    // ==================================
    // new code
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId && node.data.template
          ? {
              ...node,
              data: {
                ...node.data,
                ...shapeData,
                template: null,
              },
            }
          : node
      )
    );
    setEmailTemplateId(null);
    topRightAlert("success", "Email template removed from this activity.");
    // =================================
    // setNodes((prevNodes) =>
    //   prevNodes.map((node) =>
    //     node.id === shapeId
    //       ? {
    //           ...node,
    //           data: {
    //             ...node.data,
    //             ...shapeData,
    //             template: null,
    //           },
    //         }
    //       : node
    //   )
    // );
  };
  // method for template data edit
  const handleTemplateDataEdit = (e, templateId) => {
    e.preventDefault();
    setEmailTemplateId(templateId);
    setUpdateEmailTemplate(true);
    setEmailTemplateModalOpen(true);
  };
  // useEffect for getting email field from current model field
  useEffect(() => {
    if (forms && shapeId) {
      const uniqueOperands = new Map();
      forms.forEach((form) => {
        if (form.formbuilder_data) {
          const opr = parseFormData(form.formbuilder_data);
          opr.forEach((op) => {
            if (
              ![
                "static_image",
                "chatter",
                "button",
                "table",
                "tab",
                "group",
                "separator",
                "label",
              ].includes(op.type)
            ) {
              // Add to the map if the technicalName doesn't exist already
              if (!uniqueOperands.has(op.technicalName)) {
                uniqueOperands.set(op.technicalName, op);
              }
            }
          });
        }
      });

      // Convert the map values to an array
      const allformField = [...uniqueOperands.values()];
      const emailField = allformField?.filter(
        (field) =>
          field.widget === "email" ||
          (field.type === "many2one" &&
            ["res.partner", "res.users"].includes(field.relatedModel))
      );
      setEmailToFields([...emailField]);
    }
  }, [shapeId, forms]);
  // useEffect(() => {
  //   if (shapeId && nodes) {
  //     const currentNode = nodes.find((node) => node.id === shapeId);
  //     const prevNodeId = currentNode?.data?.prev;
  //     const prevNode = nodes.find((node) => node.id === prevNodeId);
  //     if (prevNode && prevNode?.data?.isFormSelected && prevNode?.data?.form) {
  //       // const formFields = prevNode?.data?.form.formbuilder_data;
  //       const flatFormFields = parseFormData(
  //         prevNode?.data?.form.formbuilder_data
  //       );
  //       const emailField = flatFormFields?.filter(
  //         (field) =>
  //           field.widget === "email" ||
  //           (field.type === "many2one" &&
  //             ["res.partner", "res.users"].includes(field.relatedModel))
  //       );
  //       setEmailToFields([...emailField]);
  //     }
  //   }
  // }, [shapeId]);

  // method for property tab change
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // shape update form's submit button
  const handleSubmit = () => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? { ...node, data: { ...node.data, ...shapeData } }
          : node
      )
    );
    console.log("shapeData: ", shapeData);
  };

  // useEffect for automate the submit process
  useEffect(() => {
    const intervalId = setInterval(handleSubmit, 2000); // Execute every 2 seconds
    if (conditionModalOpen) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [shapeId, shapeData, setNodes]);

  // method for field blur
  const handleBlurField = (e) => {
    e.preventDefault();
    if (e.target.name === "value") {
      const updatedNodesData = updateNodeTitle(nodes, shapeId, shapeData.value);
      setNodes(updatedNodesData);
    }
    handleSubmit();
  };

  // handling form name for duplicate name
  // const newFormName = (input) => {
  //   if (input.length === 0) {
  //     return "1";
  //   }

  //   const lastChar = input.charAt(input.length - 1);
  //   if (!isNaN(lastChar) && lastChar !== " ") {
  //     const num = parseInt(lastChar, 10);
  //     return input.slice(0, -1) + (num + 1);
  //   } else {
  //     return input + "1";
  //   }
  // };
  const newFormName = (input) => {
    const nameWithoutNumber = input.replace(/\d+$/, "").trim(); // Remove trailing digits
    const numberMatch = input.match(/(\d+)$/); // Find trailing digits, if any

    let newNumber = numberMatch ? parseInt(numberMatch[1], 10) + 1 : 1;
    return `${nameWithoutNumber} ${newNumber}`;
  };

  // method for handling kanban create
  const handleCreateKanban = () => {
    handleSubmit();
    setKanbanDetail([]);
    setIsKanbanBuilder(true);
    navigate("/form-builder");
  };
  useEffect(() => {
    setForms([]); // Clear forms before fetching new ones
    if (newProcessId) {
      fetchForms();
    }
  }, [newProcessId, setForms]);

  useEffect(() => {
    setEmailTemplates([]); // Clear forms before fetching new ones
    if (newProcessId) {
      fetchAllEmailTemplates();
    }
  }, [newProcessId, setEmailTemplates]);

  const handleFormSelect = (form) => {
    Swal.fire({
      title: "Choose your option",
      text: "Do you want to proceed with the original form or copy form?",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Original Form",
      denyButtonText: "Copy Form",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm",
        denyButton: "swal-deny",
        cancelButton: "swal-cancel",
      },
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setNodes((prevNodes) =>
          prevNodes.map((node) =>
            node.id === shapeId
              ? { ...node, data: { ...node.data, form, isFormSelected: true } }
              : node
          )
        );
        Swal.fire(
          "Original Form Selected",
          "Form assigned successfully.",
          "success"
        );
      } else if (result.isDenied) {
        copyForm(form);
      }
    });
  };

  const copyForm = async (form) => {
    try {
      let response = await tryCreatingForm(
        form,
        `${shapeData.value.replace(" ", "-")} form - copy`
      );

      if (response.ok) {
        const data = await response.json();
        console.log("copy form method response", data);
        assignFormToNode(data.formBuilder);
        Swal.fire("Copy Form Selected", data.message, "success");
      } else {
        const data = await response.json();
        console.log("copy form method response", data);
        centerAlert("error", data.message);
      }
    } catch (error) {
      showErrorAlert(error.message);
      console.error("Error creating copy form:", error);
    }
  };

  const tryCreatingForm = async (form, initialName) => {
    let attempt = 0;
    let name = initialName;
    let response;

    const payload = {
      form_builder_name: name,
      form_builder_detail: form.form_builder_detail,
      formbuilder_data: form.formbuilder_data,
      user_id: form.user_id,
      process_id: form.process_id,
    };

    // Encrypt the payload before sending
    const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);

    do {
      response = await fetch(`${BASE_URL}/form/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedPayload }),
        // body: JSON.stringify({
        //   form_builder_name: name,
        //   form_builder_detail: form.form_builder_detail,
        //   formbuilder_data: form.formbuilder_data,
        //   user_id: form.user_id,
        //   process_id: form.process_id,
        // }),
      });

      if (response.status === 409) {
        attempt += 1;
        name = `copy - ${attempt} - ${shapeData.value.trim()} form`;
      }
    } while (response.status === 409);

    return response;
  };

  const assignFormToNode = (form) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? { ...node, data: { ...node.data, form, isFormSelected: true } }
          : node
      )
    );
    setForms((prevForms) => [...prevForms, form]);
  };

  const showErrorAlert = (message) => {
    Swal.fire("Error", message, "error");
  };

  const handleFormRemove = async (formid) => {
    try {
      let formNodeCount = 0;
      nodes.forEach((node) => {
        if (node.data.form && node.data.form.form_builder_id === formid) {
          formNodeCount++;
        }
      });
      if (formNodeCount === 1) {
        const payload = {
          form_builder_id: formid,
          process_id: newProcessId,
        };
        const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);
        const response = await fetch(`${BASE_URL}/form/delete`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: encryptedPayload }),
          // body: JSON.stringify({
          //   form_builder_id: formid,
          //   process_id: newProcessId,
          // }),
        });
        if (response.ok) {
          const data = await response.json();
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === shapeId && node.data.form) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    form: "",
                    isFormSelected:
                      node.id === shapeId ? false : node.data.isFormSelected,
                  },
                };
              }
              return node;
            })
          );
          fetchForms();
          console.log("form deleted response: ", data);
          topRightAlert("success", data.message);
        } else {
          const data = await response.json();
          console.log("error in deleting form: ", data.message);
          centerAlert("error", data.message);
        }
      } else {
        setShapeData((prevShapeData) => ({
          ...prevShapeData,
          form: "",
          isFormSelected: false,
        }));
        fetchForms();
        topRightAlert("success", "Form removed from this activity.");
      }
    } catch (error) {
      console.log("error in form delete: ", error);
      centerAlert("error", error);
    }
  };

  const handleEmailTemplateSelect = (emailTemplate) => {
    console.log("email template::: on select email:: ", emailTemplate);
    const data = {
      template_id: emailTemplate._id,
      template_name: emailTemplate.template_name,
      mail_trigger: emailTemplate.mail_trigger,
      mail_limit: emailTemplate.mail_limit,
      mail_to: emailTemplate.mail_to,
      mail_from: emailTemplate.mail_from,
      mail_subject: emailTemplate.mail_subject,
      mail_body: emailTemplate.mail_body,
      attachment_file: [],
    };
    // setNodes((prevNodes) =>
    //   prevNodes.map((node) =>
    //     node.id === shapeId
    //       ? {
    //           ...node,
    //           data: {
    //             ...node.data,
    //             template: { ...data },
    //           },
    //         }
    //       : node
    //   )
    // );
    setShapeData((prevData) => {
      return { ...prevData, template: { ...data } };
    });
    Swal.fire(
      "Email Template Selected",
      "Email assigned successfully.",
      "success"
    );
  };

  // handling the kanban remove from node
  const handleKanbanRemove = async () => {
    try {
      const response = await fetch(`${BASE_URL}/form/update/kanban`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          kanban_data: null,
          process_id: newProcessId,
          form_builder_id: newFormId,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (shapeId !== null) {
          topRightAlert("success", data.message);
          setNodes((prevNodes) =>
            prevNodes.map((node) =>
              node.id === shapeId
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      kanbanData: null,
                    },
                  }
                : node
            )
          );
        }
      } else {
        topRightAlert("warning", data.message);
        console.log("error in saving kanban data: ", data);
      }
    } catch (err) {
      topRightAlert("error", err);
      console.error("error: ", err);
    }
    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      kanbanData: null,
    }));
  };

  // method for calling handleSubmit method on clicking save button
  const handleSaveClick = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  // method for removing condition
  const handleConditionRemove = (e, conIndex) => {
    e.preventDefault();
    const currentNode = nodes.find((node) => node.id === shapeId);
    const conditions = currentNode.data.next_name.filter(
      (_, index) => index !== conIndex
    );
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: { ...node.data, next_name: [...conditions] },
            }
          : node
      )
    );
  };

  // handlimg the selection of next step for decision activity
  const handleSelectChange = (index, event) => {
    const updatedConditions = shapeData.next_name;
    const node = nodes.find((nd) => nd.id === event.target.value);
    updatedConditions[index].nextStepId = event.target.value;
    updatedConditions[index].nextStep = node.data.value;
    setShapeData({ ...shapeData, next_name: updatedConditions });
  };

  // handlimg the selection of exception step for decision activity
  const handleSelectElseNextStepChange = (e) => {
    let exceptionStepObj = shapeData.elseNextStep;
    if (exceptionStepObj === null) {
      exceptionStepObj = { nextStepId: "", nextStep: "" };
    }
    const node = nodes.find((nd) => nd.id === e.target.value);
    if (e.target.value === "") {
      exceptionStepObj = null;
    } else {
      exceptionStepObj.nextStepId = e.target.value;
      exceptionStepObj.nextStep = node?.data?.value;
    }
    setShapeData({ ...shapeData, elseNextStep: exceptionStepObj });
  };
  // code for next step change of email verify activity
  const handleSelectEmailValidateStepChange = (e) => {
    const { name, value } = e.target;
    let nextStepObj = shapeData.next_name;
    if (nextStepObj === null) {
      nextStepObj = {
        valid_next: { nextStepId: "", nextStep: "" },
        invalid_next: { nextStepId: "", nextStep: "" },
      };
    }
    const node = nodes.find((nd) => nd.id === value);
    if (value === "" && name === "valid_next") {
      nextStepObj = {
        ...nextStepObj,
        valid_next: { ...nextStepObj.valid_next, nextStepId: "", nextStep: "" },
      };
    } else if (value === "" && name === "invalid_next") {
      nextStepObj = {
        ...nextStepObj,
        invalid_next: {
          ...nextStepObj.invalid_next,
          nextStepId: "",
          nextStep: "",
        },
      };
    } else {
      if (name === "valid_next" && nextStepObj.valid_next) {
        nextStepObj.valid_next.nextStepId = value;
        nextStepObj.valid_next.nextStep = node?.data?.value;
      } else if (name === "invalid_next" && nextStepObj.invalid_next) {
        nextStepObj.invalid_next.nextStepId = value;
        nextStepObj.invalid_next.nextStep = node?.data?.value;
      }
    }
    setShapeData({ ...shapeData, next_name: { ...nextStepObj } });
  };

  // handling group visibilty for fields
  // const [groupAccess, setGroupAccess] = useState("");
  const [showGroupAccessSelect, setShowGroupAccessSelect] = useState(true);
  const [filteredGroup, setFilteredGroup] = useState(null);
  const handleAddNewGroupAccess = (e) => {
    const selectedGroup = e.target.value;
    if (selectedGroup) {
      // setShapeData((prevData) => ({
      //   ...prevData,
      //   group: [...prevData.group, selectedGroup],
      // }));
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === shapeId
            ? {
                ...node,
                data: {
                  ...node.data,
                  group: [...node.data.group, selectedGroup],
                },
              }
            : node
        )
      );

      setShowGroupAccessSelect(false);
    }
  };

  const handleGroupAccessDelete = (e, index) => {
    setShapeData((prevData) => ({
      ...prevData,
      group: prevData.group.filter((_, i) => i !== index),
    }));
  };

  // useEffect for filter group by database
  useEffect(() => {
    if (groups) {
      const grps = groups.filter(
        (group) => group.database_obj === processDetail.database_obj
      );
      setFilteredGroup(grps);
    } else {
      fetchAllGroups();
    }
  }, [groups]);

  // method for prev and next drop down selection
  const formatNodesForPrevNextDropdown = () => {
    return nodes
      .filter((node) => node.id !== shapeId) // Exclude current shapeId from options
      .map((node) => ({
        value: node.id, // Use node id as value
        label: node.data.value, // Use node.data.value as label
      }));
  };

  // Handling default tab set set field for pre
  const handlePreSetFieldRemove = (e, index) => {
    e.preventDefault();
    const data = shapeData.preCondition?.set;
    data.splice(index, 1);
    setShapeData((prevData) => ({
      ...prevData,
      preCondition: {
        ...prevData.preCondition,
        set: [...data],
      },
    }));
  };
  // Handling default tab set set field for pre
  const handleAddFieldToPreSet = (data) => {
    if (
      shapeData.preCondition === null ||
      shapeData.preCondition === undefined
    ) {
      setShapeData((prevData) => ({
        ...prevData,
        preCondition: {
          ...prevData.preCondition,
          set: [],
          unset: [],
        },
      }));
    }
    setShapeData((prevData) => ({
      ...prevData,
      preCondition: {
        ...prevData.preCondition,
        set: [...prevData.preCondition.set, { ...data }],
      },
    }));
  };

  // Handling default tab set unset field for pre
  const handlePreUnsetFieldRemove = (e, index) => {
    e.preventDefault();
    const data = shapeData.preCondition?.unset;
    data.splice(index, 1);
    setShapeData((prevData) => ({
      ...prevData,
      preCondition: {
        ...prevData.preCondition,
        unset: [...data],
      },
    }));
  };
  // Handling default tab set unset field for pre
  const handleAddFieldToPreUnset = (data) => {
    if (
      shapeData.preCondition === null ||
      shapeData.preCondition === undefined
    ) {
      setShapeData((prevData) => ({
        ...prevData,
        preCondition: {
          ...prevData.preCondition,
          set: [],
          unset: [],
        },
      }));
    }
    setShapeData((prevData) => ({
      ...prevData,
      preCondition: {
        ...prevData.preCondition,
        unset: [...prevData.preCondition.unset, { ...data }],
      },
    }));
  };
  // Handling default tab set set field for post
  const handlePostSetFieldRemove = (e, index) => {
    e.preventDefault();
    const data = shapeData.postCondition?.set;
    data.splice(index, 1);
    setShapeData((prevData) => ({
      ...prevData,
      postCondition: {
        ...prevData.postCondition,
        set: [...data],
      },
    }));
  };
  // Handling default tab set set field for post
  const handleAddFieldToPostSet = (data) => {
    if (
      shapeData.postCondition === null ||
      shapeData.postCondition === undefined
    ) {
      setShapeData((prevData) => ({
        ...prevData,
        postCondition: {
          ...prevData.postCondition,
          set: [],
          unset: [],
        },
      }));
    }
    setShapeData((prevData) => ({
      ...prevData,
      postCondition: {
        ...prevData.postCondition,
        set: [...prevData.postCondition.set, { ...data }],
      },
    }));
  };
  // Handling default tab set unset field for post
  const handlePostUnsetFieldRemove = (e, index) => {
    e.preventDefault();
    const data = shapeData.postCondition?.unset;
    data.splice(index, 1);
    setShapeData((prevData) => ({
      ...prevData,
      postCondition: {
        ...prevData.postCondition,
        unset: [...data],
      },
    }));
  };
  // Handling default tab set unset field for post
  const handleAddFieldToPostUnset = (data) => {
    if (
      shapeData.postCondition === null ||
      shapeData.postCondition === undefined
    ) {
      setShapeData((prevData) => ({
        ...prevData,
        postCondition: {
          ...prevData.postCondition,
          set: [],
          unset: [],
        },
      }));
    }
    setShapeData((prevData) => ({
      ...prevData,
      postCondition: {
        ...prevData.postCondition,
        unset: [...prevData.postCondition.unset, { ...data }],
      },
    }));
  };

  // methods for action tab fields
  // state for storing array field
  const [actions, setActions] = useState([]);
  const [availableActions, setAvailableActions] = useState([
    "Create Customer",
    "Create Vendor",
    "Create Invoice",
  ]);
  const handleRemoveAction = (actionToRemove) => {
    setActions(actions.filter((action) => action !== actionToRemove));
    setAvailableActions([...availableActions, actionToRemove]);
  };

  console.log("shapeId: ", shapeId);
  console.log("formId: ", newFormId);
  console.log("emailToField::::: ", emailToFields);
  // console.log("allFormsData: ", JSON.stringify(forms));
  // console.log("selectedConditions: ", selectedConditions);

  return (
    <div className="suf_container">
      <form action="" onSubmit={handleSaveClick} className="suf_container_form">
        <button
          className="close_btn"
          onClick={() => setShowShapeUpdateForm(false)}
        >
          <IoMdClose style={{ fontSize: "30px" }} />
        </button>
        <h4>{shapeData?.value ? shapeData.value : "Activity"} Properties</h4>
        {shapeId === null ? (
          <></>
        ) : (
          <>
            {/* delete node button  */}
            {/* <button
              className="btn btn-danger pm_shape_del_btn"
              onClick={handleDelete}
            >
              <MdDeleteOutline className="pm_shape_del_btn_icon" />
            </button> */}
            <ul className="saf_tab_box">
              {tabs.map((tab) => (
                <li
                  key={tab}
                  className={`${
                    ["Views", "Advance"].includes(tab) &&
                    ["decision", "email", "email_verify"].includes(
                      shapeData.type
                    )
                      ? "d-none"
                      : ""
                  } ${
                    shapeData.type !== "email" && tab === "Template"
                      ? "d-none"
                      : ""
                  } ${
                    shapeData.type !== "start" && tab === "Advance"
                      ? "d-none"
                      : ""
                  }`}
                >
                  {["Action", "DocTypes", "ToDos"].includes(tab) ? (
                    <button
                      className={`saf_tab_button ${
                        activeTab === tab ? "active_tab" : ""
                      }`}
                      title="Coming Soon Feature"
                      style={{ cursor: "not-allowed" }}
                      // onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </button>
                  ) : (
                    <button
                      className={`saf_tab_button ${
                        activeTab === tab ? "active_tab" : ""
                      }`}
                      onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </button>
                  )}
                </li>
              ))}
            </ul>
            <div
              className={`${
                activeTab === "General" ? "saf_tab_general_box" : "d-none"
              } `}
            >
              <div className="suf_inpfield mt-2">
                <label htmlFor="type">Activity Type:</label>
                <input
                  id="type"
                  type="text"
                  name="type"
                  style={{ backgroundColor: "#eee" }}
                  value={shapeData.type}
                  readOnly
                />
              </div>
              <div className="suf_inpfield mt-1">
                <label htmlFor="title">Activity Name:</label>
                <input
                  id="title"
                  type="text"
                  placeholder="Enter title here..."
                  name="value"
                  value={shapeData.value}
                  className="nodrag"
                  onChange={handleInputChange}
                  onBlur={handleBlurField}
                  readOnly={
                    ["decision"].includes(shapeData.type) ? true : false
                  }
                />
              </div>
              <div className="suf_inpfield mt-1">
                <label htmlFor="group">Activity Group:</label>
                <div className="suf_group_access_box">
                  {shapeData && shapeData.group.length > 0 && (
                    <ul>
                      {Array.isArray(shapeData.group) &&
                        shapeData.group.map((groupName, index) => (
                          <li key={index} className="suf_group_access_list">
                            <span style={{ fontSize: "14px" }}>
                              {index + 1}.
                            </span>
                            <span className="suf_group_access_name_span">
                              {groupName}
                            </span>
                            <button
                              className="suf_group_access_delete"
                              onClick={(e) => handleGroupAccessDelete(e, index)}
                            >
                              <GiCancel />
                            </button>
                          </li>
                        ))}
                    </ul>
                  )}
                  {showGroupAccessSelect ? (
                    <div className="suf_group_access_select_box">
                      <select
                        name="group"
                        id="group"
                        onChange={handleAddNewGroupAccess}
                        onBlur={handleBlurField}
                      >
                        <option value="">Select Group</option>
                        {filteredGroup?.map((group, index) => (
                          <option
                            key={index}
                            value={`base.${group.group_internal}`}
                          >
                            {group.group_name}
                            {/* {`(base.${group.group_internal})`} */}
                          </option>
                        ))}
                        {/* {groups?.map((group, index) => (
                          <option
                            key={index}
                            value={`base.${group.group_internal}`}
                          >
                            {group.group_name}
                            {`(base.${group.group_internal})`}
                          </option>
                        ))} */}
                      </select>
                      <button
                        className="suf_group_access_close_button"
                        onClick={() => setShowGroupAccessSelect(false)}
                      >
                        <MdOutlineClose />
                      </button>
                    </div>
                  ) : (
                    <>
                      <span
                        onClick={() => setShowGroupAccessSelect(true)}
                        className="suf_group_access_show_select"
                      >
                        Add group
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${
                activeTab === "Default" ? "saf_tab_doctype_box" : "d-none"
              } `}
            >
              <DefaultTab
                shapeData={shapeData}
                handleConditionRemove={handleConditionRemove}
                handleSelectChange={handleSelectChange}
                handleBlurField={handleBlurField}
                handleSelectElseNextStepChange={handleSelectElseNextStepChange}
                handleInputChange={handleInputChange}
                handleAddFieldToPreSet={handleAddFieldToPreSet}
                handlePreSetFieldRemove={handlePreSetFieldRemove}
                handlePreUnsetFieldRemove={handlePreUnsetFieldRemove}
                handleAddFieldToPreUnset={handleAddFieldToPreUnset}
                handleAddFieldToPostSet={handleAddFieldToPostSet}
                handlePostSetFieldRemove={handlePostSetFieldRemove}
                handlePostUnsetFieldRemove={handlePostUnsetFieldRemove}
                handleAddFieldToPostUnset={handleAddFieldToPostUnset}
              />
            </div>

            <div
              className={`${
                activeTab === "Steps" ? "saf_tab_steps_box" : "d-none"
              } `}
            >
              <div className="suf_inpfield align-items-center mt-1">
                <label className="align-self-start">Activity Steps:</label>
                <div className="suf_inpfield d-none">
                  <label htmlFor="currentStep">Current Step:</label>
                  <input
                    id="currentStep"
                    type="text"
                    name="current"
                    value={shapeData.value}
                    style={{ cursor: "not-allowed" }}
                    className="nodrag"
                    onChange={handleInputChange}
                    onBlur={handleBlurField}
                    readOnly
                  />
                </div>
                {!["start", "webform"].includes(shapeData.type) && (
                  <div className="suf_sub_inpfield">
                    <label htmlFor="prevStep">Previous Step</label>
                    <CustomDropdown
                      name="prev"
                      defaultValue={shapeData.prev || ""}
                      options={formatNodesForPrevNextDropdown()}
                      onChange={(event) => handleStepsChange(event)}
                      onBlur={handleBlurField}
                      width={"90%"}
                      height={"25px"}
                    />
                  </div>
                )}
                {/* code for normal next step */}
                {![
                  "email_verify",
                  "decision",
                  "exception",
                  "discard",
                  "reject",
                  "end",
                ].includes(shapeData.type) && (
                  <div className="suf_sub_inpfield">
                    <label htmlFor="nextStep">Next Step</label>
                    <CustomDropdown
                      name="next"
                      defaultValue={shapeData.next || ""}
                      options={formatNodesForPrevNextDropdown()}
                      onChange={(event) => handleStepsChange(event)}
                      onBlur={handleBlurField}
                      width={"90%"}
                      height={"25px"}
                    />
                  </div>
                )}
                {/* code for email verify next step */}
                {shapeData.type === "email_verify" && (
                  <>
                    <div className="suf_sub_inpfield mt-2">
                      <label htmlFor="nextStep">Next Step on Verified</label>
                      <CustomDropdown
                        name="valid_next"
                        defaultValue={
                          shapeData.next_name?.valid_next?.nextStepId || ""
                        }
                        options={formatNodesForPrevNextDropdown()}
                        onChange={(event) =>
                          handleSelectEmailValidateStepChange(event)
                        }
                        onBlur={handleBlurField}
                        width={"90%"}
                        height={"25px"}
                      />
                    </div>
                    <div className="suf_sub_inpfield mt-1">
                      <label htmlFor="nextStep">
                        Next Step on not Verified
                      </label>
                      <CustomDropdown
                        name="invalid_next"
                        defaultValue={
                          shapeData.next_name?.invalid_next?.nextStepId || ""
                        }
                        options={formatNodesForPrevNextDropdown()}
                        onChange={(event) =>
                          handleSelectEmailValidateStepChange(event)
                        }
                        onBlur={handleBlurField}
                        width={"90%"}
                        height={"25px"}
                      />
                    </div>
                  </>
                )}
              </div>
              {/* code for multi condition */}
              {shapeData.type === "decision" && (
                <div className="suf_inpfield mt-1">
                  <label className="align-self-start">Conditions:</label>
                  {shapeData.next_name && shapeData.next_name.length > 0 && (
                    <>
                      <div
                        className="ps-3 d-flex flex-column justify-content-center align-content-center gap-2"
                        style={{ width: "95%" }}
                      >
                        {shapeData.next_name.map((conditionData, index) => (
                          <div
                            className="d-flex flex-column justify-content-center align-items-start gap-1 mt-2"
                            key={index}
                            style={{ width: "100%" }}
                          >
                            <div
                              className="d-flex justify-content-between align-items-start"
                              style={{ width: "100%" }}
                            >
                              <div
                                className="d-flex justify-content-start align-items-start gap-2"
                                style={{ width: "100%" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  {index + 1}
                                  {"."}
                                </span>
                                <span
                                  style={{
                                    width: "90%",
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                    lineBreak: "anywhere",
                                    lineClamp: "3",
                                    fontSize: "12px",
                                  }}
                                >
                                  {JSON.stringify(
                                    conditionData.condition,
                                    null,
                                    2
                                  )}
                                </span>
                              </div>
                              <button
                                onClick={(e) => handleConditionRemove(e, index)}
                                className="condition_delete_button"
                              >
                                <MdDelete />
                              </button>
                            </div>
                            <div className="suf_sub_inpfield">
                              <label htmlFor={`next${index + 1}`}>
                                Next Step
                              </label>
                              <select
                                id={`next${index + 1}`}
                                name={`next${index + 1}`}
                                value={
                                  shapeData.next_name
                                    ? shapeData.next_name[index].nextStepId
                                    : ""
                                }
                                onChange={(event) =>
                                  handleSelectChange(index, event)
                                }
                                onBlur={handleBlurField}
                              >
                                <option value="">Select next activity</option>
                                {nodes.map((node) => {
                                  if (node.id !== shapeId) {
                                    return (
                                      <option key={node.id} value={node.id}>
                                        {node.data.value}
                                      </option>
                                    );
                                  }
                                  return null;
                                })}
                              </select>
                            </div>
                          </div>
                        ))}
                        {/* code for exception case field */}
                        <div className="suf_sub_inpfield">
                          <label htmlFor="elseNextStep">Exception Step</label>
                          <select
                            id="elseNextStep"
                            name="elseNextStep"
                            value={shapeData?.elseNextStep?.nextStepId || ""}
                            onChange={handleSelectElseNextStepChange}
                            onBlur={handleBlurField}
                          >
                            <option value="">Select exception activity</option>
                            {nodes.map((node) => {
                              if (node.id !== shapeId) {
                                return (
                                  <option key={node.id} value={node.id}>
                                    {node.data.value}
                                  </option>
                                );
                              }
                              return null;
                            })}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  <ConditionContainer shapeData={shapeData} />
                </div>
              )}
            </div>

            <div
              className={`${
                activeTab === "Views" ? "saf_tab_views_box" : "d-none"
              } ${
                activeTab === "Views" &&
                ["decision", "email", "email_verify"].includes(shapeData.type)
                  ? "d-none"
                  : ""
              } `}
            >
              <div className="suf_inpfield mt-2">
                {shapeData.isFormSelected && <label>Assign Form:</label>}
                {shapeData.isFormSelected ? (
                  <SelectedForm
                    sltdForm={shapeData.form}
                    handleFormRemove={handleFormRemove}
                  />
                ) : (
                  <>
                    <label>Assign Form:</label>
                    <SelectField
                      options={
                        forms.length > 0
                          ? [
                              { value: "create", label: "Create Form" },
                              { value: "existing", label: "Existing Form" },
                            ]
                          : [{ value: "create", label: "Create Form" }]
                      }
                      // options={select_options}
                      forms={forms}
                      handleFormSelect={handleFormSelect}
                      handleBlurField={handleBlurField}
                    />
                  </>
                )}
              </div>
              {shapeData.isFormSelected && (
                <div className="suf_inpfield mt-1">
                  {shapeData.kanbanData && <label>Assign Kanban:</label>}
                  {shapeData.kanbanData ? (
                    <SelectedKanban
                      sltdForm={shapeData.form}
                      handleKanbanRemove={handleKanbanRemove}
                    />
                  ) : (
                    <button
                      style={{ cursor: "not-allowed" }}
                      title="Coming soon feature"
                      className="create_kanban_button"
                      // onClick={handleCreateKanban}
                    >
                      <PiKanban style={{ fontSize: "20px" }} /> Create Kanban
                    </button>
                  )}
                </div>
              )}
            </div>
            <div
              className={`${
                activeTab === "Advance" ? "saf_tab_views_box" : "d-none"
              } ${
                activeTab === "Advance" && !["start"].includes(shapeData.type)
                  ? // ["decision", "email"].includes(shapeData.type)
                    "d-none"
                  : ""
              } `}
            >
              <div className="suf_inpfield mt-1">
                <label htmlFor="isApiEnable">Configure Api</label>
                {/* <div
                  className="d-flex justify-content-between align-content-center"
                  style={{ width: "80%" }}
                >
                  <label>Enable Webform</label>
                  <input type="checkbox" />
                </div> */}
                <div className="fb_field_details_form_group_checkbox_container">
                  <label htmlFor="fieldIsApiEnable" className="label_checkbox">
                    Enable Json Api
                  </label>
                  <label className="checkbox_container">
                    <input
                      type="checkbox"
                      id="fieldIsApiEnable"
                      name="isApiEnable"
                      checked={shapeData.isApiEnable}
                      onChange={handleInputChange}
                      onBlur={handleBlurField}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                {shapeData?.isApiEnable && (
                  <>
                    {shapeData.isFormSelected && shapeData.form ? (
                      <div
                        className="suf_inpfield mt-1"
                        style={{ width: "90%" }}
                      >
                        <li className="ms-3" style={{ width: "100%" }}>
                          {showApiEnableUrl ? (
                            <FaEye
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginLeft: "0px",
                                marginRight: "3px",
                              }}
                              onClick={() => setShowApiEnableUrl(false)}
                            />
                          ) : (
                            <FaEyeSlash
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginLeft: "0px",
                                marginRight: "3px",
                              }}
                              onClick={() => setShowApiEnableUrl(true)}
                            />
                          )}
                          URL:{" "}
                          {showApiEnableUrl && (
                            <p
                              style={{
                                fontSize: "14px",
                                width: "90%",
                                wordBreak: "break-all",
                                wordWrap: "break-word",
                                lineBreak: "anywhere",
                              }}
                            >{`${BASE_URL}/${API_MIDPOINT}/o2b.${processDetail?.process_name
                              ?.trim()
                              .toLowerCase()
                              .replace(/ /g, "_")}/${shapeId}`}</p>
                          )}
                        </li>
                        <ExportFormFieldJson sltdForm={shapeData.form} />
                      </div>
                    ) : (
                      <label>No Form found on this activity.</label>
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              className={`${
                activeTab === "Template" ? "saf_tab_general_box" : "d-none"
              } `}
            >
              {shapeData?.template !== null ? (
                <>
                  <div className="suf_inpfield mt-1">
                    <label htmlFor="title">Email Template:</label>
                    <div
                      className="d-flex justify-content-between align-items-start"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="d-flex flex-column justify-content-start align-align-items-start gap-1"
                        style={{
                          width: "80%",
                          overflow: "hidden",
                          wordBreak: "break-word",
                          wordWrap: "break-word",
                          lineClamp: "2",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                        }}
                      >
                        <span>
                          Template Name: {shapeData?.template?.template_name}
                        </span>
                      </div>
                      <div className="d-flex justify-content-center align-items-start gap-2">
                        <button
                          className="template_edit_button"
                          onClick={(e) =>
                            handleTemplateDataEdit(
                              e,
                              shapeData?.template?.template_id
                            )
                          }
                        >
                          <MdEditNote style={{ fontSize: "25px" }} />
                        </button>
                        <button
                          className="template_remove_button"
                          onClick={(e) =>
                            handleTemplateRemove(
                              e,
                              shapeData?.template?.template_id
                            )
                          }
                        >
                          <FaDeleteLeft style={{ fontSize: "20px" }} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="suf_inpfield mt-1">
                    <label htmlFor="emailTo">Email To:</label>
                    <select
                      name="emailTo"
                      id="emailTo"
                      value={shapeData?.emailTo || ""}
                      onChange={handleEmailToChange}
                      onBlur={handleBlurField}
                    >
                      <option value="">Select</option>
                      {emailToFields &&
                        emailToFields?.map((field) => (
                          <option
                            key={`${field.technicalName}-${field.title}`}
                            value={`${field.technicalName}(${field?.relatedModel})`}
                          >
                            {field.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </>
              ) : (
                // <div className="suf_inpfield mt-1">
                //   <li className="ms-3">No template added.</li>
                //   <button
                //     className="docType_create_btn mt-2"
                //     data-bs-toggle="modal"
                //     data-bs-target="#emailTemplateModal"
                //     onClick={(e) => {
                //       e.preventDefault();
                //       setUpdateEmailTemplate(false);
                //       setEmailTemplateId(null);
                //     }}
                //   >
                //     Add Template
                //   </button>
                // </div>
                <>
                  <div className="suf_inpfield mt-2">
                    <label className="">Assign email:</label>
                    <SelectEmailTemplate
                      options={
                        emailTemplates.length > 0
                          ? [
                              {
                                value: "create",
                                label: "Create Email Template",
                              },
                              {
                                value: "existing",
                                label: "Existing Email Template",
                              },
                            ]
                          : [
                              {
                                value: "create",
                                label: "Create Email Template",
                              },
                            ]
                      }
                      // options={select_options}
                      emailTemplates={emailTemplates}
                      handleEmailTemplateSelect={handleEmailTemplateSelect}
                      handleBlurField={handleBlurField}
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className={`${
                activeTab === "Action" ? "saf_tab_doctype_box" : "d-none"
              } `}
            >
              {actions.map((action) => {
                if (action === "Create Customer") {
                  return (
                    <CreateCustomer handleRemoveAction={handleRemoveAction} />
                  );
                } else if (action === "Create Vendor") {
                  return (
                    <CreateVendor handleRemoveAction={handleRemoveAction} />
                  );
                } else if (action === "Create Invoice") {
                  return (
                    <CreateInvoice handleRemoveAction={handleRemoveAction} />
                  );
                }
                return null;
              })}

              <ActionComponent
                actions={actions}
                setActions={setActions}
                availableActions={availableActions}
                setAvailableActions={setAvailableActions}
                shapeData={shapeData}
              />
            </div>
            <div
              className={`${
                activeTab === "DocTypes" ? "saf_tab_doctype_box" : "d-none"
              } `}
            >
              {Array.isArray(doctypes) && doctypes?.length > 0 ? (
                <>
                  <div className="suf_inpfield mt-1">
                    <label htmlFor="title">Attach Doctype:</label>
                    <select name="" id="">
                      <option value="">Select doctype</option>
                      {doctypes.map((doctype, index) => (
                        <option key={index} value={doctype.docType_type}>
                          {doctype.docType_type}
                        </option>
                      ))}
                    </select>
                    {/* <input
                      id="title"
                      type="text"
                      placeholder="Enter title here..."
                      name="value"
                      value={shapeData.value}
                      className="nodrag"
                      onChange={handleInputChange}
                      onBlur={handleBlurField}
                    /> */}
                  </div>
                </>
              ) : (
                <>
                  {/* {showDocTypeForm ? (
                    <>
                      <DoctypeForm
                        showDocTypeForm={showDocTypeForm}
                        setShowDocTypeForm={setShowDocTypeForm}
                      />
                    </>
                  ) : (
                    <div className="suf_inpfield mt-1">
                      <li className="ms-3">Doctype is not available</li>
                      <button
                        className="docType_create_btn mt-2"
                        onClick={() => setShowDocTypeForm(true)}
                      >
                        Create Doctype
                      </button>
                    </div>
                  )} */}
                </>
              )}
            </div>

            {/* <button type="submit" className="btn pb_save_button">
              <FaSave style={{ fontSize: "20px" }} /> Save
            </button> */}
          </>
        )}
      </form>
      {/* Modal */}
      <FormBuilderOverlay />
      <EmailTemplateOverlay />
      <CustomerCreateOverlay shapeData={shapeData} />
      <VendorCreateOverlay shapeData={shapeData} />
      <InvoiceCreateOverlay shapeData={shapeData} />
      {/* <ConditionalModalOverlay setConditionModalOpen={setConditionModalOpen} /> */}
      {/* over modal code */}
    </div>
  );
};

export default memo(ShapeUpdateForm);
