import React, { useEffect, useState } from "react";
import "./FormBuilderOverlay.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useProcessContext } from "../../context/ProcessContext";
import BASE_URL, { PAYLOAD_SECRET } from "../../../config";
import { useFormContext } from "../../context/FormContext";
import { useNavigate } from "react-router-dom";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import Swal from "sweetalert2";
import { IoMdClose } from "react-icons/io";

const FormBuilderOverlay = () => {
  const navigate = useNavigate();
  const {
    userData,
    newProcessId,
    processDetail,
    centerAlert,
    centerAlertWithTitle,
    encryptData,
  } = useProcessContext();
  const { forms, setForms, setNewFormId, setFormData, setIsKanbanBuilder } =
    useFormContext();
  const [formbuilderFormData, setFormbuilderFormData] = useState({
    form_name: "",
    form_detail: "",
    user_id: userData.userid,
    process_id: newProcessId,
  });

  // handling form name for duplicate name
  // const newFormName = (input) => {
  //   if (input.length === 0) {
  //     return "1";
  //   }

  //   const lastChar = input.charAt(input.length - 1);
  //   if (!isNaN(lastChar) && lastChar !== " ") {
  //     const num = parseInt(lastChar, 10);
  //     return input.slice(0, -1) + (num + 1);
  //   } else {
  //     return input + "1";
  //   }
  // };

  // Function to handle appending/incrementing numbers in case of duplicate names
  const newFormName = (input) => {
    const nameWithoutNumber = input.replace(/\d+$/, "").trim(); // Remove trailing digits
    const numberMatch = input.match(/(\d+)$/); // Find trailing digits, if any

    let newNumber = numberMatch ? parseInt(numberMatch[1], 10) + 1 : 1;
    return `${nameWithoutNumber} ${newNumber}`;
  };

  // useEffect for getting processName
  useEffect(() => {
    if (newProcessId) {
      // const processData = processdata()
      formbuilderFormData.form_name = processDetail
        ? `${processDetail.process_name} form`
        : "No Process Selected";
      formbuilderFormData.form_detail = processDetail
        ? `${processDetail.process_name} form`
        : "No Process Selected";
      // setProcessName(processData[0]);
    }
  }, [newProcessId]);

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setFormbuilderFormData({ ...formbuilderFormData, [name]: value });
  };
  const handleReset = () =>
    setFormbuilderFormData({
      ...formbuilderFormData,
      form_name: "",
      form_detail: "",
    });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Form validation
  //   if (!formbuilderFormData.form_name || !formbuilderFormData.form_detail) {
  //     centerAlert("Please fill form name field.", "error");
  //     return;
  //   }

  //   try {
  //     const response = await fetch(`${BASE_URL}/form/create`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         form_builder_name: formbuilderFormData.form_name,
  //         form_builder_detail: formbuilderFormData.form_detail,
  //         user_id: formbuilderFormData.user_id,
  //         process_id: formbuilderFormData.process_id,
  //       }),
  //     });
  //     const data = await response.json();
  //     if (response.ok) {
  //       setIsKanbanBuilder(false);
  //       setFormData([]);
  //       centerAlertWithTitle(
  //         "Form created Successfully",
  //         "Please design your form by dragging fields.",
  //         "success"
  //       );
  //       setForms([...forms, data.formBuilder]);
  //       setNewFormId(data.formBuilder.form_builder_id);
  //       console.log("responseData form data:", data);
  //       handleReset();
  //       navigate("/form-builder");
  //     } else if (response.status === 409) {
  //       let newName = `${newFormName(formbuilderFormData.form_name.trim())}`;
  //       const response = await fetch(`${BASE_URL}/form/create`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           form_builder_name: newName,
  //           form_builder_detail: formbuilderFormData.form_detail,
  //           user_id: formbuilderFormData.user_id,
  //           process_id: formbuilderFormData.process_id,
  //         }),
  //       });
  //       const data = await response.json();
  //       if (response.ok) {
  //         setIsKanbanBuilder(false);
  //         setFormData([]);
  //         centerAlertWithTitle(
  //           "Form created Successfully",
  //           "Please design your form by dragging fields.",
  //           "success"
  //         );
  //         setForms([...forms, data.formBuilder]);
  //         setNewFormId(data.formBuilder.form_builder_id);
  //         console.log("responseData form data:", data);
  //         handleReset();
  //         navigate("/form-builder");
  //       } else if (response.status === 409) {
  //         let againNewName = `${newFormName(newName.trim())}`;
  //         const response = await fetch(`${BASE_URL}/form/create`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             form_builder_name: againNewName,
  //             form_builder_detail: formbuilderFormData.form_detail,
  //             user_id: formbuilderFormData.user_id,
  //             process_id: formbuilderFormData.process_id,
  //           }),
  //         });
  //         const data = await response.json();
  //         if (response.ok) {
  //           setIsKanbanBuilder(false);
  //           setFormData([]);
  //           centerAlertWithTitle(
  //             "Form created Successfully",
  //             "Please design your form by dragging fields.",
  //             "success"
  //           );
  //           setForms([...forms, data.formBuilder]);
  //           setNewFormId(data.formBuilder.form_builder_id);
  //           console.log("responseData form data:", data);
  //           handleReset();
  //           navigate("/form-builder");
  //         } else {
  //           centerAlert("error", data.message);
  //         }
  //       } else {
  //         centerAlert("error", data.message);
  //       }
  //     } else {
  //       centerAlert("error", data.message);
  //       console.error("Error creating new form:", data);
  //     }
  //   } catch (error) {
  //     centerAlert("error", error);
  //     console.error("Error creating new form:", error);
  //   }
  //   // console.log("formbuilderFormData: ", formbuilderFormData);
  //   handleReset();
  //   // Manually hide the modal
  //   const modalElement = document.getElementById("formModal");
  //   const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
  //   modalInstance.hide();
  // };

  // method for hanling form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!formbuilderFormData.form_name || !formbuilderFormData.form_detail) {
      centerAlert("Please fill form name field.", "error");
      return;
    }

    let currentName = formbuilderFormData.form_name.trim();
    let isConflict = true;

    while (isConflict) {
      try {
        const payload = {
          form_builder_name: currentName,
          form_builder_detail: formbuilderFormData.form_detail,
          user_id: formbuilderFormData.user_id,
          process_id: formbuilderFormData.process_id,
        };

        // Encrypt the payload before sending
        const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);
        const response = await fetch(`${BASE_URL}/form/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: encryptedPayload }),
          // body: JSON.stringify({
          //   form_builder_name: currentName,
          //   form_builder_detail: formbuilderFormData.form_detail,
          //   user_id: formbuilderFormData.user_id,
          //   process_id: formbuilderFormData.process_id,
          // }),
        });

        const data = await response.json();

        if (response.ok) {
          setIsKanbanBuilder(false);
          setFormData([]);
          centerAlertWithTitle(
            "Form created Successfully",
            "Please design your form by dragging fields.",
            "success"
          );
          setForms([...forms, data.formBuilder]);
          setNewFormId(data.formBuilder.form_builder_id);
          console.log("responseData form data:", data);
          handleReset();
          navigate("/form-builder");
          isConflict = false; // Break out of the loop, name is unique
        } else if (response.status === 409) {
          // If there's a conflict, modify the name and try again
          currentName = newFormName(currentName); // Use the updated name logic
        } else {
          centerAlert("error", data.message);
          console.error("Error creating new form:", data);
          break; // Break out if there's another error
        }
      } catch (error) {
        centerAlert("error", error);
        console.error("Error creating new form:", error);
        break;
      }
    }

    handleReset();
    // Manually hide the modal
    const modalElement = document.getElementById("formModal");
    const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
    modalInstance.hide();
  };
  // console.log("newProcessId: ",newProcessId);
  // console.log("processName:::::::::::::::: ", processName);
  return (
    <div
      className="modal fade"
      id="formModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="formModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content form_modal_content">
          <div className="modal-header form_modal_header">
            <h1 className="modal-title fs-5" id="formModalLabel">
              Form Detail
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form
            method="post"
            onSubmit={handleSubmit}
            className="form_create_modal"
          >
            <div className="modal-body form_modal_body">
              <div className="formbuilderModalForm">
                <div className="formbuilderModalForm_inputField">
                  <label htmlFor="form_name">Form Name*</label>
                  <input
                    type="text"
                    id="form_name"
                    placeholder="Enter form name"
                    name="form_name"
                    value={formbuilderFormData.form_name}
                    onChange={handleDataChange}
                    required
                  />
                </div>
                <div className="formbuilderModalForm_inputField">
                  <label htmlFor="form_detail">Form Detail</label>
                  <input
                    type="text"
                    id="form_detail"
                    placeholder="Enter form detail"
                    name="form_detail"
                    value={formbuilderFormData.form_detail}
                    onChange={handleDataChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer form_modal_footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn modal_save_button">
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormBuilderOverlay;
