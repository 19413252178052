import React, { useEffect, useState } from "react";
import "./CodeEditorPage.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormContext } from "../../context/FormContext";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../config";

const CodeEditorPage = () => {
  const navigate = useNavigate();
  const {
    formData,
    buttonData,
    setFormData,
    setIsKanbanBuilder,
    setSelectedFieldData,
    pythonCode,
  } = useFormContext();
  const [content, setContent] = useState("");

  useEffect(() => {
    if (pythonCode === null) {
      fetch(`${BASE_URL}/parse/hello`)
        .then((response) => response.text())
        .then((content) => {
          const text = JSON.parse(content).file_content;
          console.log("text: ", text);
          setContent(text);
        })
        .catch((error) => console.log("Error loading Python file:", error));
    } else {
      setContent(pythonCode);
    }
  }, []);
  const handlePythonCodeUpdate = (data) => {
    let updatedFormData = [...formData];

    // updating field inside group
    if (buttonData.groupField !== undefined) {
      const { rowIndex, columnIndex, groupRowIndex, groupFieldIndex } =
        buttonData;
      if (groupRowIndex !== undefined && groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex][
          groupFieldIndex
        ].pythonCode = data;
      } else if (groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[
          groupFieldIndex
        ].pythonCode = data;
      } else {
        updatedFormData[rowIndex][columnIndex].pythonCode = data;
      }
    } else if (buttonData.tabField !== undefined) {
      // updating field inside tabfield
      const { rowIndex, columnIndex, tabIndex, tabRowIndex, tabFieldIndex } =
        buttonData;
      if (tabRowIndex !== undefined && tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].pythonCode = data;
      } else if (tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabFieldIndex
        ].pythonCode = data;
      } else {
        updatedFormData[rowIndex][columnIndex].pythonCode = data;
      }
    } else {
      // updating top level field
      updatedFormData = updatedFormData.map((row) =>
        row.map((field) =>
          field.type === buttonData.type && field.title === buttonData.title
            ? { ...field, pythonCode: data }
            : field
        )
      );
    }

    setFormData(updatedFormData);
  };
  const handleSaveClick = (e) => {
    e.preventDefault();
    handlePythonCodeUpdate(content);
    setIsKanbanBuilder(false);
    if (buttonData.tabField !== undefined) {
      buttonData.tabField.pythonCode = content;
    } else if (buttonData.groupField !== undefined) {
      buttonData.groupField.pythonCode = content;
    } else {
      buttonData.pythonCode = content;
    }
    setSelectedFieldData({ ...buttonData });
    navigate("/form-builder");
  };

  return (
    <div
      style={{
        margin: "100px",
        marginTop: "10px",
        // background: "#23241f",
        // color: "white",
      }}
    >
      <div id="editor" style={{ padding: "20px" }}>
        <ReactQuill value={content} onChange={setContent} theme="snow" />
      </div>
      <button className="cde_save_code" onClick={handleSaveClick}>
        Save Code
      </button>
    </div>
  );
};

export default CodeEditorPage;
