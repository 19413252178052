import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "./components/pages/login_page/LoginPage";
import RegisterPage from "./components/pages/register_page/RegisterPage";
import { useProcessContext } from "./components/context/ProcessContext";
import Dashboard from "./components/pages/dashboard_page/Dashboard";
import { memo, useEffect, useState } from "react";
import Header from "./components/template/header/Header";
import ProcessModelerPage from "./components/pages/process_modeler_page/ProcessModelerPage";
import FormBuilderPage from "./components/pages/form_builder_page/FormBuilderPage";
import LogOut from "./components/template/logout_overlay/LogOut";
import CodeEditorPage from "./components/pages/code_editor_page/CodeEditorPage";
import ConfigurationPage from "./components/pages/configure_page/ConfigurationPage";
import ExistingHutchForm from "./components/pages/configure_page/configure_page_content/ExistingHutchForm";
import HutchUpdateForm from "./components/pages/configure_page/configure_page_content/HutchUpdateForm";
import Groups from "./components/pages/dashboard_page/Groups";
import Processes from "./components/pages/dashboard_page/Processes";
import Configurations from "./components/pages/dashboard_page/Configurations";
import NewHutchForm from "./components/pages/configure_page/configure_page_content/NewHutchForm";
import ForgetPassword from "./components/pages/forget_password_page/ForgetPassword";
import NewPasswordPage from "./components/pages/forget_password_page/NewPasswordPage";
import { useAdminContext } from "./components/context/AdminContext";
import AdminLoginPage from "./components/pages/login_page/AdminLoginPage";
import AdminDashboard from "./components/pages/dashboard_page/AdminDashboard";
import Users from "./components/pages/dashboard_page/Users";

function App() {
  const { adminAuthenticated, setAdminAuthenticated } = useAdminContext();
  const { authenticated, setAuthenticated, logoutClicked } =
    useProcessContext();
  const location = useLocation();
  const [isAuthPage, setIsAuthPage] = useState(false);
  const [isAdminAuthPage, setIsAdminAuthPage] = useState(false);

  // Initialize authentication state
  useEffect(() => {
    const authState = localStorage.getItem("authenticated");
    if (authState) {
      setAuthenticated(JSON.parse(authState));
    }
  }, [setAuthenticated]);

  // Initialize admin authentication state
  useEffect(() => {
    const adminAuthState = sessionStorage.getItem("adminAuthenticated");
    if (adminAuthState) {
      setAdminAuthenticated(JSON.parse(adminAuthState));
    }
  }, [setAdminAuthenticated]);

  // Redirect to login page if user not authenticated
  useEffect(() => {
    if (
      !authenticated &&
      (location.pathname === "/" || location.pathname === "/register")
    ) {
      setIsAuthPage(true);
    } else {
      setIsAuthPage(false);
    }
  }, [authenticated, location]);

  // Redirect to login page if admin not authenticated
  useEffect(() => {
    if (
      !adminAuthenticated &&
      (location.pathname === "/admin" ||
        location.pathname === "/admin/register")
    ) {
      setIsAdminAuthPage(true);
    } else {
      setIsAdminAuthPage(false);
    }
  }, [adminAuthenticated, location]);

  // Redirect to dashboard if user already authenticated
  useEffect(() => {
    if (
      authenticated &&
      (location.pathname === "/" || location.pathname === "/register")
    ) {
      setIsAuthPage(false);
    }
  }, [authenticated, location]);

  // Redirect to dashboard if admin already authenticated
  useEffect(() => {
    if (
      adminAuthenticated &&
      (location.pathname === "/admin" ||
        location.pathname === "/admin/register")
    ) {
      setIsAdminAuthPage(false);
    }
  }, [adminAuthenticated, location]);

  return (
    <div className={`App ${isAuthPage || isAdminAuthPage ? "auth-page" : ""}`}>
      {/* {authenticated && <Header />} */}
      {!isAuthPage &&
        !isAdminAuthPage &&
        (adminAuthenticated || authenticated) && <Header />}
      {authenticated && logoutClicked && <LogOut />}
      {adminAuthenticated && logoutClicked && <LogOut />}
      <Routes>
        <Route
          path="/"
          element={authenticated ? <Navigate to="/dashboard" /> : <LoginPage />}
        />
        <Route
          path="/admin"
          element={
            adminAuthenticated ? (
              <Navigate to="/admin-dashboard" />
            ) : (
              <AdminLoginPage />
            )
          }
        />

        <Route
          path="/admin-dashboard/*"
          element={
            adminAuthenticated ? <AdminDashboard /> : <Navigate to="/admin" />
          }
        >
          <Route index element={<AdminDashboard />} />
          <Route path="users" element={<Users />} />
        </Route>
        <Route
          path="/dashboard/*"
          element={authenticated ? <Dashboard /> : <Navigate to="/" />}
        >
          <Route index element={<Dashboard />} />
          <Route path="groups" element={<Groups />} />
          <Route path="processes" element={<Processes />} />
          <Route path="configurations" element={<Configurations />} />
        </Route>
        {/* <Route
          path="/dashboard/*"
          element={authenticated ? <Dashboard /> : <Navigate to="/" />}
        /> */}
        <Route
          path="/register"
          element={
            authenticated ? <Navigate to="/dashboard" /> : <RegisterPage />
          }
        />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<NewPasswordPage />} />
        <Route
          path="/process-modeler"
          element={authenticated ? <ProcessModelerPage /> : <Navigate to="/" />}
        />
        <Route
          path="/form-builder"
          element={authenticated ? <FormBuilderPage /> : <Navigate to="/" />}
        />
        <Route
          path="/configuration"
          element={
            authenticated ? (
              <Navigate to="/dashboard/configurations/hutch" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/dashboard/configurations"
          element={<Navigate to="/dashboard/configurations/hutch" />}
        />
        <Route
          path="/configuration/existing-hutch"
          element={authenticated ? <ExistingHutchForm /> : <Navigate to="/" />}
        />
        <Route
          path="/configuration/new-hutch"
          element={authenticated ? <NewHutchForm /> : <Navigate to="/" />}
        />
        <Route
          path="/configuration/update"
          element={authenticated ? <HutchUpdateForm /> : <Navigate to="/" />}
        />
        <Route
          path="/code-editor"
          element={authenticated ? <CodeEditorPage /> : <Navigate to="/" />}
        />
      </Routes>
    </div>
  );
}

export default memo(App);
