import React, { useEffect, useState } from "react";
import "./ProcessModelerTopBar.css";
import { useProcessContext } from "../../context/ProcessContext";

const ProcessModelerTopBar = () => {
  const { newProcessId, processDetail } = useProcessContext();
  const [processName, setProcessName] = useState("no name")
  useEffect(() => {
    if (newProcessId && processDetail) {
      
      setProcessName(processDetail.process_name)
    }
  }, [newProcessId]);
  return (
    <div className="pm_topbar_container">
      <span>{processDetail ? processName : "no process"}</span>
    </div>
  );
};

export default ProcessModelerTopBar;
