import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ProcessStore from "./components/context/ProcessContext";
import { BrowserRouter as Router } from "react-router-dom";
import { NodeProvider } from "./components/context/NodeContext";
import FormStore from "./components/context/FormContext";
import AdminStore from "./components/context/AdminContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AdminStore>
      <ProcessStore>
        <NodeProvider>
          <FormStore>
            <Router>
              <App />
            </Router>
          </FormStore>
        </NodeProvider>
      </ProcessStore>
    </AdminStore>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
