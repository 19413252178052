import React, { useEffect, useState } from "react";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";
import { CircleLoader } from "react-spinners";
import { RiDeleteBin5Line } from "react-icons/ri";
import EmailTemplateOverlay from "../../template/email_template_modal/EmailTemplateOverlay";
import { CiEdit } from "react-icons/ci";
import { useNodeContext } from "../../context/NodeContext";

const EmailConfigurationPage = () => {
  const {
    userData,
    emailTemplates,
    setEmailTemplates,
    fetchAllEmailTemplates,
    topRightAlert,
    centerAlert,
    setEmailTemplateId,
    setUpdateEmailTemplate,
    setEmailTemplateModalOpen,
    encryptData,
    newProcessId,
    setNewProcessId,
  } = useProcessContext();
  const { setShapeId } = useNodeContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loading spinner
      await fetchAllEmailTemplates(); // Wait for the API call to finish
      setLoading(false); // Hide loading spinner after data is loaded
    };
    fetchData();
  }, [setEmailTemplates, userData.userid]);

  // method for edit email template
  // method for template data edit
  const handleTemplateDataEdit = (e, templateId) => {
    e.preventDefault();
    setShapeId(null);
    setNewProcessId(null)
    setEmailTemplateId(templateId);
    setUpdateEmailTemplate(true);
    setEmailTemplateModalOpen(true);
  };
  // method for delete email
  const handleDelete = async (tempId) => {
    try {
      const payload = {
        user_id: userData.userid,
        email_id: tempId,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/email/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
        // body: JSON.stringify({
        //   user_id: userData.userid,
        //   email_id: tempId,
        //   key: API_KEY,
        // }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("delete process: ", data);
        topRightAlert("success", data.message);
        // Filter out the deleted process from the state
        // setProcesses((prevProcesses) =>
        //   prevProcesses.filter((process) => process.process_id !== processId)
        // );
        fetchAllEmailTemplates();
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error deleting process:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error deleting process:", error);
    }
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <CircleLoader color="#007485" />
      </div>
    );
  return (
    <div className="donor_dash_right">
      <div className="dashboard_buttons">
        <button
          data-bs-toggle="modal"
          data-bs-target="#emailTemplateModal"
          onClick={() => setShapeId(null)}
        >
          Create Email Template
        </button>
      </div>
      {/* Modal */}
      {/* <GroupModalOverlay fetchAllGroups={fetchAllGroups} /> */}
      <EmailTemplateOverlay />
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">
            List of existing email templates
          </h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Template Id</th>
                  <th>Template Name</th>
                  <th>Email Subject</th>
                  <th>Email Trigger</th>
                  <th>Email Limit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {emailTemplates.length > 0 ? (
                  emailTemplates.map((emailTemp, index) => (
                    <tr key={emailTemp._id}>
                      <th>{index + 1}</th>
                      <td>{emailTemp._id}</td>
                      <td>{emailTemp.template_name}</td>
                      <td>{emailTemp.mail_subject}</td>
                      <td>{emailTemp.mail_trigger}</td>
                      <td>{emailTemp.mail_limit}</td>
                      <td className="td_action_cell">
                        <CiEdit
                          className="dash_table_icon_edit"
                          onClick={(e) =>
                            handleTemplateDataEdit(e, emailTemp._id)
                          }
                        />
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() => handleDelete(emailTemp._id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={userData.userid}>
                    <td colSpan={7} className="text-center ">
                      No existing email templates...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfigurationPage;
