import React, { useState, useEffect, memo } from "react";
import ConditionFields from "./ConditionFields";
import "../../pages/process_modeler_page/process_modeler_content/ShapeUpdateForm.css";
import { useNodeContext } from "../../context/NodeContext";

const ConditionContainer = ({ shapeData }) => {
  const { nodes, setNodes, shapeId } = useNodeContext();
  const [conditions, setConditions] = useState([
    { condition: {}, operator: "&" },
  ]);
  // const [allConditions, setAllConditions] = useState([]);
  const [showConditionFields, setShowConditionFields] = useState(false);
  const [currentConditions, setCurrentConditions] = useState([]);

  const handleConditionChange = (index, newCondition) => {
    const newConditions = [...conditions];
    newConditions[index].condition = newCondition;
    setConditions(newConditions);
  };

  const handleAddCondition = () => {
    setConditions([...conditions, { condition: {}, operator: "&" }]);
  };

  const handleRemoveCondition = (index) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
  };

  const handleOperatorChange = (index, operator) => {
    const newConditions = [...conditions];
    newConditions[index].operator = operator;
    setConditions(newConditions);
  };

  const formatOdooDomain = (conds) => {
    let domain = [];
    conds.forEach((cond, index) => {
      console.log("conds.length: ", conds.length);

      domain.push([
        cond.condition.conditionOperand,
        cond.condition.conditionOperator,
        cond.condition.conditionValue,
      ]);
      if (index > 0 || conds.length > 1) {
        if (index < conds.length - 1) {
          domain.push(cond.operator);
        }
      }
    });
    return domain;
  };

  const handleNewRule = () => {
    const odooDomain = formatOdooDomain(conditions);
    let allConditions = [];
    if (shapeData.next_name) {
      allConditions = shapeData.next_name;
    }
    allConditions.push({ condition: odooDomain, nextStep: "", nextStepId: "" });
    console.log("allConditions::::: ", allConditions);
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === shapeId) {
          return {
            ...node,
            data: {
              ...node.data,
              next_name: [...allConditions],
            },
          };
        }
        return node;
      })
    );
    setConditions([{ condition: {}, operator: "&" }]);
    setShowConditionFields(false);
  };

  useEffect(() => {
    setCurrentConditions(formatOdooDomain(conditions));
  }, [conditions]);

  // console.log("allConditions: ", JSON.stringify(allConditions));
  console.log("conditions::::: ", conditions);
  
  return (
    <div className="parent_component_container">
      {showConditionFields && (
        <label className="align-self-start">New Condition:</label>
      )}
      {showConditionFields ? (
        <>
          {conditions.map((conditionData, index) => (
            <div key={index} className="condition_wrapper">
              <ConditionFields
                index={index}
                handleConditionChange={handleConditionChange}
                handleRemoveCondition={handleRemoveCondition}
              />
              {index < conditions.length - 1 && (
                <select
                  value={conditions[index].operator}
                  onChange={(e) => handleOperatorChange(index, e.target.value)}
                  style={{ width: "50px", height: "20px" }}
                >
                  <option value="&">&</option>
                  <option value="|">|</option>
                </select>
              )}
            </div>
          ))}
          <button
            onClick={handleAddCondition}
            className="condition_control_button"
          >
            Add Rule
          </button>
          <button onClick={handleNewRule} className="condition_control_button">
            Save
          </button>
          <button
            onClick={() => setShowConditionFields(false)}
            className="condition_control_button"
          >
            Close
          </button>
        </>
      ) : (
        <span
          className="conditional_link"
          onClick={() => setShowConditionFields(true)}
        >
          {shapeData?.next_name?.length > 0
            ? "Add More Condition"
            : "Add Condition"}
        </span>
      )}
    </div>
  );
};

export default memo(ConditionContainer);
