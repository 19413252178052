import React, { useState } from "react";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import { useProcessContext } from "../../context/ProcessContext";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { useAdminContext } from "../../context/AdminContext";

const LoginPage = () => {
  const { setAdminAuthenticated, setAdminData } = useAdminContext();
  const {
    setAuthenticated,
    setUserData,
    topRightAlert,
    centerAlert,
    encryptData,
  } = useProcessContext();
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleResetForm = () => {
    setLoginData({ ...loginData, email: "", password: "" });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = { ...loginData, key: API_KEY };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET)
      const response = await fetch(`${BASE_URL}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
        // body: JSON.stringify({ ...loginData, key: API_KEY }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("User logged in:", data);
        topRightAlert("success", data.message);
        setAuthenticated(true);
        setUserData(data.user);
        setAdminAuthenticated(false);
        setAdminData(null);
        handleResetForm();
        navigate("/dashboard/configurations/hutch");
      } else {
        centerAlert("error", data.message);
        console.error("Error logging in:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100 p-l-60 p-r-60 p-t-40 p-b-40">
          <span className="login100-form-title p-b-30"> Login </span>
          <form
            className="login100-form validate-form flex-sb flex-w"
            method="post"
            onSubmit={handleLoginSubmit}
          >
            <span className="txt1 p-b-11"> Email address </span>
            <div
              className="wrap-input100 validate-input"
              data-validate="Username is required"
            >
              <input
                className="input100"
                type="email"
                name="email"
                value={loginData.email}
                onChange={handleInputChange}
                placeholder="enter your email here..."
                required
              />
              <span className="focus-input100" />
            </div>
            <div className="input-st">
              We'll never share your email with anyone else.
            </div>
            <span className="txt1 p-b-11"> Password </span>
            <div
              className="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <input
                className="input100"
                type="password"
                name="password"
                value={loginData.password}
                onChange={handleInputChange}
                placeholder="enter your password here..."
                required
              />
              <span className="focus-input100" />
            </div>
            <div className="m-b-36 mt-2 ms-2">
              <Link
                to="/forget-password"
                style={{ color: "#007485", fontSize: "13px" }}
              >
                Forget Password
              </Link>
            </div>
            <div className="container-login100-form-btn">
              <button type="submit" className="login100-form-btn">
                Login
              </button>
            </div>
            <div className="input-st p-t-20">
              Create New Account{" "}
              <Link to="/register" style={{ color: "#007485" }}>
                Register Here
              </Link>
            </div>
          </form>
        </div>
        <div className="background-shape">
          <div className="shape" />
          <div className="shape" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
