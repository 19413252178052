import React, { useState } from "react";
import CustomDropdown from "../../../template/custom_dropdown/CustomDropdown";
import { useProcessContext } from "../../../context/ProcessContext";

const ActionComponent = ({
  actions,
  setActions,
  availableActions,
  setAvailableActions,
  shapeData,
}) => {
  const { centerAlert } = useProcessContext();
  const [selectedAction, setSelectedAction] = useState("");
  const [showSelect, setShowSelect] = useState(false);

  const handleActionSelect = (e) => {
    const action = e.target.value;
    setSelectedAction(action);
    setActions([...actions, action]);
    if (Array.isArray(availableActions) && availableActions.length > 0) {
      setAvailableActions((prevAvailable) =>
        prevAvailable.filter((i) => i !== action)
      );
    } else {
      setAvailableActions([]);
    }
    setShowSelect(false);
  };

  const handleAddMore = () => {
    if (shapeData.form !== null && shapeData.form !== "") {
      setShowSelect(true);
    } else {
      centerAlert("error", "Please assign form first on this activity.");
    }
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center gap-1"
      style={{ width: "100%" }}
    >
      {actions.length === 0 && (
        <li className="align-self-start ms-3">There is no integrated action</li>
      )}
      {/* <label className="align-self-start ms-3">Action</label> */}
      {showSelect ? (
        <>
          <label className="align-self-start ms-3">Action</label>
          <CustomDropdown
            defaultValue={""}
            options={availableActions}
            onChange={(event) => handleActionSelect(event)}
            width={"90%"}
            height={"25px"}
          />
        </>
      ) : (
        <>
          {availableActions.length > 0 && (
            <button
              className="docType_create_btn mt-2 align-self-start ms-3"
              onClick={handleAddMore}
            >
              {actions?.length > 0 ? "Add More Action" : "Add Action"}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ActionComponent;
