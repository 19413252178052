import React, { useEffect } from "react";
import "./DashBoard.css";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Groups from "./Groups";
import Processes from "./Processes";
import Configurations from "./Configurations";
import Users from "./Users";

const AdminDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Save the current dashboard route in session storage whenever it changes
  useEffect(() => {
    if (location.pathname.startsWith("/admin-dashboard")) {
      sessionStorage.setItem("lastVisitedDashboardRoute", location.pathname);
    }
  }, [location]);

  // On component mount, redirect to the last visited dashboard route if it exists
  useEffect(() => {
    const lastVisitedRoute = sessionStorage.getItem(
      "lastVisitedDashboardRoute"
    );
    if (lastVisitedRoute && lastVisitedRoute !== "/admin-dashboard") {
      navigate(lastVisitedRoute);
    }
  }, [navigate]);

  return (
    <div className="dashBoard">
      <div className="">
        <div className="donor_row">
          <div className="donor_dash_left">
            <div className="donor_dash_side">
              <div className="donor_side_menu">
                <ul className="donor_menu">
                  <li>
                    <Link
                      to="/admin-dashboard/users"
                      className={` ${
                        location.pathname === "/admin-dashboard/users"
                          ? "active"
                          : ""
                      }`}
                    >
                      {" "}
                      Users{" "}
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/dashboard/groups"
                      className={` ${
                        location.pathname === "/dashboard/groups"
                          ? "active"
                          : ""
                      }`}
                    >
                      {" "}
                      Groups{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dashboard/configurations"
                      className={` ${
                        location.pathname === "/dashboard/configurations"
                          ? "active"
                          : ""
                      }`}
                    >
                      {" "}
                      Configurations{" "}
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/admin-dashboard" element={<Navigate to="/admin-dashboard/users" />} />
            <Route path="/users" element={<Users />} />
            {/* <Route path="/configurations" element={<Configurations />} />
            <Route path="/groups" element={<Groups />} /> */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
