import React, { useEffect, useState } from "react";
import "./Header.css";
import { FaUserTie } from "react-icons/fa";
import { useProcessContext } from "../../context/ProcessContext";
import { NavLink, useLocation } from "react-router-dom";
import { useAdminContext } from "../../context/AdminContext";

const Header = () => {
  const { adminData } = useAdminContext();
  const { userData, logoutClicked, setLogoutClicked } = useProcessContext();
  const location = useLocation();
  const handleLogoutToggle = () => setLogoutClicked(!logoutClicked);
  const [lastDashRoute, setLastDashRoute] = useState(null);
  useEffect(() => {
    const lastRoute = sessionStorage.getItem("lastVisitedDashboardRoute");
    if (lastRoute && lastRoute !== "") {
      setLastDashRoute(lastRoute);
    } else {
      if (adminData) {
        setLastDashRoute("/admin-dashboard/users");
      } else {
        setLastDashRoute("/dashboard/processes");
      }
    }
  }, [location.pathname]);

  return (
    <nav className="navbar navbar-expand-lg text-white">
      <div className="container-fluid">
        <NavLink
          to={adminData ? "/admin-dashboard/users" : "/dashboard"}
          className="navbar-brand text-white"
        >
          OflowAI
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
            <li className="nav-item ">
              <NavLink
                to={
                  location.pathname.startsWith("/dashboard")
                    ? location.pathname
                    : lastDashRoute
                }
                // to={"/dashboard"}
                className={`nav-link ${
                  location.pathname.startsWith("/dashboard") ? "active" : ""
                }`}
                aria-current="page"
              >
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/process-modeler"}
                className={`nav-link ${
                  location.pathname === "/process-modeler" ? "active" : "d-none"
                }`}
                aria-current="page"
              >
                Process Modeler
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/form-builder"}
                className={`nav-link ${
                  location.pathname === "/form-builder" ? "active" : "d-none"
                }`}
              >
                Form Builder
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/configuration"}
                className={`nav-link ${
                  location.pathname === "/configuration" ? "active" : "d-none"
                }`}
              >
                Configuration
              </NavLink>
            </li>
          </ul>
          <div className="right" onClick={handleLogoutToggle}>
            <FaUserTie className="profileIcon" />
            <span className="profileName">
              {adminData ? adminData?.username : userData?.username}
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
