import React, { useEffect, useState } from "react";
import "./CreateCustomerOverlay.css";
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../../../config";
import { useFormContext } from "../../context/FormContext";
import { IoMdClose } from "react-icons/io";
import { FaArrowRightLong, FaDeleteLeft } from "react-icons/fa6";
import CustomDropdown from "../custom_dropdown/CustomDropdown";

const CustomerCreateOverlay = ({ shapeData }) => {
  //   const navigate = useNavigate();
  const [currentFormField, setCurrentFormField] = useState(null);
  const [selectedField, setSelectedField] = useState({
    currentField: "",
    customerField: "",
  });
  const [allMappedField, setAllMappedField] = useState([]);
  const { parseFormData } = useFormContext();
  const handleChangeSelctedData = (e) => {
    const { name, value } = e.target;
    setSelectedField((prev) => ({ ...prev, [name]: value }));
  };

  const handleRemoveMappedField = (e, index) => {
    e.preventDefault();
    const newMappedField = [...allMappedField];
    newMappedField.splice(index, 1);
    setAllMappedField([...newMappedField]);
  };
  const handleMappedFieldData = (e) => {
    e.preventDefault();
    setAllMappedField([...allMappedField, selectedField]);
    e.target.value = "";
    setSelectedField({
      currentField: "",
      customerField: "",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (shapeData.form !== null && shapeData.form !== "") {
      const fields = parseFormData(shapeData.form.formbuilder_data);
      setCurrentFormField(fields);
    }
  }, [shapeData.form]);

  console.log("sssshapedata::::: ", shapeData);
  console.log("ssssssCurentFormFieldData::::::: ", currentFormField);
  console.log("ssssssSelectedFieldData::::::: ", selectedField);
  return (
    <div
      className="modal fade"
      id="createCustomerModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="createCustomerModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content form_modal_content">
          <div className="modal-header form_modal_header">
            <h1 className="modal-title fs-5" id="formModalLabel">
              Create Customer
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form
            method="post"
            onSubmit={handleSubmit}
            className="form_create_modal"
          >
            <div className="modal-body form_modal_body">
              <div className="actionModalForm_inputfields">
                <div className="formbuilderModalForm_inputField">
                  <label htmlFor="form_name">Current Form Field</label>
                  <CustomDropdown
                    name="currentField"
                    defaultValue={selectedField.currentField}
                    options={currentFormField?.map((field) => {
                      return field.title;
                    })}
                    onChange={(event) => handleChangeSelctedData(event)}
                    width={"90%"}
                    height={"40px"}
                  />
                </div>
                <span
                  style={{
                    color: "black",
                    fontSize: "25px",
                    alignSelf: "flex-end",
                    marginRight: "15px",
                  }}
                >
                  <FaArrowRightLong />
                </span>
                <div className="formbuilderModalForm_inputField">
                  <label htmlFor="form_detail">Customer Form Field</label>
                  <CustomDropdown
                    name="customerField"
                    defaultValue={selectedField.customerField}
                    options={currentFormField?.map((field) => {
                      return field.title;
                    })}
                    onChange={(event) => handleChangeSelctedData(event)}
                    width={"90%"}
                    height={"40px"}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }} className="mt-3">
                <button
                  className="action_map_both_button"
                  onClick={handleMappedFieldData}
                >
                  Map
                </button>
              </div>
              {allMappedField.length > 0 && (
                <div className="mt-4" style={{ width: "100%" }}>
                  <label
                    htmlFor=""
                    style={{
                      color: "#383838",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Mapped Fields
                  </label>
                  <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                    {allMappedField.map((field, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-content-center"
                        style={{ width: "90%" }}
                      >
                        <div
                          className="d-flex justify-content-start align-content-center gap-1"
                          style={{ width: "80%", color: "#383838" }}
                        >
                          <strong
                            style={{ marginRight: "5px", fontSize: "18px" }}
                          >
                            {index + 1}
                          </strong>
                          <span
                            style={{ marginRight: "5px", marginLeft: "5px" }}
                          >
                            {field.currentField}
                          </span>
                          <span style={{}}>
                            <FaArrowRightLong />
                          </span>
                          <span style={{}}>{field.customerField}</span>
                        </div>
                        <div className="d-flex justify-content-center align-content-center">
                          <button
                            className="action_delete_button"
                            onClick={(e) => handleRemoveMappedField(e, index)}
                          >
                            <FaDeleteLeft style={{ fontSize: "20px" }} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer form_modal_footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn modal_save_button">
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerCreateOverlay;
