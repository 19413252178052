import React, { createContext, useContext, useEffect, useState } from "react";

const AdminContext = createContext();

const AdminStore = ({ children }) => {
  const [adminAuthenticated, setAdminAuthenticated] = useState(() => {
    const authState = sessionStorage.getItem("adminAuthenticated");
    return authState ? JSON.parse(authState) : false;
  });

  const [adminData, setAdminData] = useState(() => {
    const adminData = sessionStorage.getItem("adminData");
    return adminData ? JSON.parse(adminData) : null;
  });
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    sessionStorage.setItem(
      "adminAuthenticated",
      JSON.stringify(adminAuthenticated)
    );
  }, [adminAuthenticated]);

  useEffect(() => {
    sessionStorage.setItem("adminData", JSON.stringify(adminData));
  }, [adminData]);
  return (
    <AdminContext.Provider
      value={{
        adminAuthenticated,
        setAdminAuthenticated,
        adminData,
        setAdminData,
        allUsers,
        setAllUsers,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminStore;

export const useAdminContext = () => useContext(AdminContext);
