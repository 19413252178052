import React, { memo } from "react";
import PropTypes from "prop-types";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "@xyflow/react";

import "./buttonedge.css";
import { useNodeContext } from "../../../../context/NodeContext";

const ButtonEdge = ({
  id,
  selected,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) => {
  const { setNodes, edges, setEdges } = useNodeContext();
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    const edge = edges.find((edge) => id === edge.id);
    const { source, target } = edge;
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === source) {
          return {
            ...node,
            data: {
              ...node.data,
              next: null,
              next_name: null,
            },
          };
        }
        if (node.id === target) {
          return {
            ...node,
            data: {
              ...node.data,
              prev: null,
              prev_name: null,
            },
          };
        }
        return node;
      })
    );
  };
  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
          title="Remove Edge"
        >
          {selected && (
            <button className="edgebutton" onClick={onEdgeClick}>
              ×
            </button>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

ButtonEdge.propTypes = {
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  sourcePosition: PropTypes.string.isRequired,
  targetPosition: PropTypes.string.isRequired,
  style: PropTypes.object,
  markerEnd: PropTypes.string,
};

ButtonEdge.defaultProps = {
  style: {},
  markerEnd: null,
};

export default memo(ButtonEdge);
