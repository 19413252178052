import React, { useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import DecisionRule from "../../../../template/decision_rule_fields/DecisionRuleFields";
import { TbPointFilled } from "react-icons/tb";
import { GiCancel } from "react-icons/gi";
import { useFormContext } from "../../../../context/FormContext";

const DefaultTab = ({
  shapeData,
  handleBlurField,
  handleInputChange,
  handlePreSetFieldRemove,
  handleAddFieldToPreSet,
  handlePreUnsetFieldRemove,
  handleAddFieldToPreUnset,
  handlePostSetFieldRemove,
  handleAddFieldToPostSet,
  handlePostUnsetFieldRemove,
  handleAddFieldToPostUnset,
}) => {
  const { parseFormData } = useFormContext();
  const [formFields, setFormFields] = useState(null);
  const [fieldSetUnset, setFieldSetUnset] = useState({
    field: "",
    value: "",
    condition: "",
  });
  const [showInputFields, setShowInputFields] = useState({
    preSet: false,
    preUnset: false,
    postSet: false,
    postUnset: false,
  });
  const handleReset = () =>
    setFieldSetUnset({
      field: "",
      value: "",
      condition: "",
    });

  const handleFieldSetUnsetConditionDelete = (e) => {
    e.preventDefault();
    setFieldSetUnset((prevData) => ({ ...prevData, condition: "" }));
  };

  useEffect(() => {
    if (shapeData.form.formbuilder_data && shapeData.form) {
      const newFields = parseFormData(shapeData.form.formbuilder_data);
      setFormFields([...newFields]);
    }
  }, [shapeData.form]);

  const handleFieldSetUnsetChange = (e) => {
    const { name, value } = e.target;
    setFieldSetUnset({ ...fieldSetUnset, [name]: value });
  };
  const handlePreSetAdd = () => {
    handleAddFieldToPreSet({ ...fieldSetUnset });
    handleReset();
  };
  const handlePreUnsetAdd = () => {
    handleAddFieldToPreUnset({ ...fieldSetUnset });
    handleReset();
  };
  const handlePostSetAdd = () => {
    handleAddFieldToPostSet({ ...fieldSetUnset });
    handleReset();
  };
  const handlePostUnsetAdd = () => {
    handleAddFieldToPostUnset({ ...fieldSetUnset });
    handleReset();
  };

  console.log("prePost Data :::::::::: ", showInputFields);

  return (
    <div className="suf_inpfield mt-1" style={{ width: "90%" }}>
      <label>Add Pre/Post values</label>
      <div className="fb_field_details_form_group_checkbox_container">
        <label htmlFor="fieldIsPreCondition" className="label_checkbox">
          Pre Condition
        </label>
        <label className="checkbox_container">
          <input
            type="checkbox"
            id="fieldIsPreCondition"
            name="isPreCondition"
            checked={shapeData.isPreCondition}
            onChange={handleInputChange}
            onBlur={handleBlurField}
          />
          <span className="checkmark" />
        </label>
      </div>
      {shapeData.isPreCondition && (
        <>
          {/* code for pre set fields */}
          <div style={{ width: "80%" }}>
            {shapeData.preCondition?.set &&
              shapeData.preCondition?.set?.length > 0 && (
                <>
                  <details>
                    <summary>Pre Set Fields</summary>
                    <div
                      className="d-flex flex-column justify-content-start align-items-start gap-2"
                      style={{ width: "100%" }}
                    >
                      {shapeData.preCondition.set.map((setField, index) => (
                        <div
                          key={`${setField.field}_${index}_${setField.value}`}
                          className="d-flex justify-content-between align-items-start ms-1"
                          style={{ width: "100%" }}
                        >
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            {index + 1}
                            {"."}
                          </span>
                          <div
                            className="d-flex flex-column justify-content-start align-items-start gap-1"
                            style={{ width: "80%" }}
                          >
                            <div className="d-flex justify-content-start align-items-start gap-2">
                              {" "}
                              <span style={{ fontSize: "14px" }}>
                                {setField.field}
                              </span>
                              <span style={{ fontSize: "14px" }}>=&gt;</span>
                              <span style={{ fontSize: "14px" }}>
                                {setField.value}
                              </span>
                            </div>
                            <span
                              style={{
                                width: "90%",
                                wordBreak: "break-all",
                                wordWrap: "break-word",
                                lineBreak: "anywhere",
                                lineClamp: "3",
                                fontSize: "12px",
                              }}
                            >
                              Condition:{" "}
                              {JSON.stringify(setField.condition, null, 2)}
                            </span>
                          </div>
                          <button
                            onClick={(e) => handlePreSetFieldRemove(e, index)}
                            className="condition_delete_button"
                          >
                            <MdDelete />
                          </button>
                        </div>
                      ))}
                    </div>
                  </details>
                </>
              )}
            {showInputFields.preSet ? (
              <>
                <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                  <label htmlFor="">Add set field</label>
                  <div className="d-flex justify-content-start align-items-start gap-2">
                    <select
                      name="field"
                      onChange={handleFieldSetUnsetChange}
                      value={fieldSetUnset.field}
                    >
                      <option>Select Field</option>
                      {formFields &&
                        formFields.map((field) => (
                          <option
                            key={field.technicalName}
                            value={field.technicalName}
                          >
                            {field.title}
                          </option>
                        ))}
                    </select>

                    <input
                      type="text"
                      placeholder="select value"
                      name="value"
                      value={fieldSetUnset.value}
                      onChange={handleFieldSetUnsetChange}
                    />
                  </div>
                  {fieldSetUnset.condition === "" ||
                  fieldSetUnset.condition === null ||
                  fieldSetUnset.condition === undefined ? (
                    <DecisionRule
                      form={shapeData.form.formbuilder_data}
                      saveRuleData={handleFieldSetUnsetChange}
                      ruleName="condition"
                    />
                  ) : (
                    <div
                      style={{ width: "90%" }}
                      className="ms-3 d-flex justify-content-between align-items-center"
                    >
                      <p className="mb-0">
                        <span>
                          <TbPointFilled />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            wordBreak: "break-all",
                            lineBreak: "anywhere",
                            wordWrap: "break-word",
                          }}
                        >
                          {fieldSetUnset.condition}
                        </span>
                      </p>
                      <button
                        className="fdu_group_delete"
                        onClick={handleFieldSetUnsetConditionDelete}
                      >
                        <GiCancel />
                      </button>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-start align-items-start gap-2">
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={handlePreSetAdd}
                    style={{ fontSize: "14px" }}
                  >
                    Add Field
                  </button>
                  <button
                    className="btn btn-secondary mt-2"
                    style={{ fontSize: "14px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowInputFields({ ...showInputFields, preSet: false });
                    }}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : Array.isArray(shapeData.preCondition?.set) &&
              shapeData.preCondition?.set?.length > 0 ? (
              <div>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInputFields({ ...showInputFields, preSet: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Add Set Field
                </button>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-start align-items-start gap-1">
                <li style={{ fontSize: "14px" }}>
                  There is not any pre set fields.
                </li>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInputFields({ ...showInputFields, preSet: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Add Pre Set field
                </button>
              </div>
            )}
          </div>

          {/* code for pre unset fields */}
          <div style={{ width: "80%" }}>
            {shapeData.preCondition?.unset &&
              shapeData.preCondition?.unset?.length > 0 && (
                <>
                  <details>
                    <summary>Pre Unset Fields</summary>
                    <div
                      className="d-flex flex-column justify-content-start align-items-start gap-2"
                      style={{ width: "100%" }}
                    >
                      {shapeData.preCondition.unset.map((unsetField, index) => (
                        <div
                          key={`${unsetField.field}_${index}_${unsetField.value}`}
                          className="d-flex justify-content-between align-items-start ms-1"
                          style={{ width: "100%" }}
                        >
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            {index + 1}
                            {"."}
                          </span>
                          <div
                            className="d-flex flex-column justify-content-start align-items-start gap-1"
                            style={{ width: "80%" }}
                          >
                            <div className="d-flex justify-content-start align-items-start gap-2">
                              {" "}
                              <span style={{ fontSize: "14px" }}>
                                {unsetField.field}
                              </span>
                              <span style={{ fontSize: "14px" }}>=&gt;</span>
                              <span style={{ fontSize: "14px" }}>
                                {unsetField.value}
                              </span>
                            </div>
                            <span
                              style={{
                                width: "90%",
                                wordBreak: "break-all",
                                wordWrap: "break-word",
                                lineBreak: "anywhere",
                                lineClamp: "3",
                                fontSize: "12px",
                              }}
                            >
                              Condition:{" "}
                              {JSON.stringify(unsetField.condition, null, 2)}
                            </span>
                          </div>
                          <button
                            onClick={(e) => handlePreUnsetFieldRemove(e, index)}
                            className="condition_delete_button"
                          >
                            <MdDelete />
                          </button>
                        </div>
                      ))}
                    </div>
                  </details>
                </>
              )}
            {showInputFields.preUnset ? (
              <>
                <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                  <label htmlFor="">Add unset field</label>
                  <div className="d-flex justify-content-start align-items-start gap-2">
                    <select
                      name="field"
                      onChange={handleFieldSetUnsetChange}
                      value={fieldSetUnset.field}
                    >
                      <option>Select Field</option>
                      {formFields &&
                        formFields.map((field) => (
                          <option
                            key={field.technicalName}
                            value={field.technicalName}
                          >
                            {field.title}
                          </option>
                        ))}
                    </select>
                    <input
                      type="text"
                      placeholder="select value"
                      name="value"
                      value={fieldSetUnset.value}
                      onChange={handleFieldSetUnsetChange}
                    />
                  </div>
                  {fieldSetUnset.condition === "" ||
                  fieldSetUnset.condition === null ||
                  fieldSetUnset.condition === undefined ? (
                    <DecisionRule
                      form={shapeData.form.formbuilder_data}
                      saveRuleData={handleFieldSetUnsetChange}
                      ruleName="condition"
                    />
                  ) : (
                    <div
                      style={{ width: "90%" }}
                      className="ms-3 d-flex justify-content-between align-items-center"
                    >
                      <p className="mb-0">
                        <span>
                          <TbPointFilled />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            wordBreak: "break-all",
                            lineBreak: "anywhere",
                            wordWrap: "break-word",
                          }}
                        >
                          {fieldSetUnset.condition}
                        </span>
                      </p>
                      <button
                        className="fdu_group_delete"
                        onClick={handleFieldSetUnsetConditionDelete}
                      >
                        <GiCancel />
                      </button>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-start align-items-start gap-2">
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={handlePreUnsetAdd}
                    style={{ fontSize: "14px" }}
                  >
                    Add Field
                  </button>
                  <button
                    className="btn btn-secondary mt-2"
                    style={{ fontSize: "14px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowInputFields({
                        ...showInputFields,
                        preUnset: false,
                      });
                    }}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : Array.isArray(shapeData.preCondition?.unset) &&
              shapeData.preCondition?.unset?.length > 0 ? (
              <div>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInputFields({ ...showInputFields, preUnset: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Add Unset Field
                </button>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-start align-items-start gap-1">
                <li style={{ fontSize: "14px" }}>
                  There is not any pre unset fields.
                </li>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInputFields({ ...showInputFields, preUnset: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Add Pre Unset field
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <div className="fb_field_details_form_group_checkbox_container">
        <label htmlFor="fieldIsPostCondition" className="label_checkbox">
          Post Condition
        </label>
        <label className="checkbox_container">
          <input
            type="checkbox"
            id="fieldIsPostCondition"
            name="isPostCondition"
            checked={shapeData.isPostCondition}
            onChange={handleInputChange}
            onBlur={handleBlurField}
          />
          <span className="checkmark" />
        </label>
      </div>
      {shapeData.isPostCondition && (
        <>
          {/* code for post set fields */}
          <div style={{ width: "80%" }}>
            {shapeData.postCondition?.set &&
              shapeData.postCondition?.set?.length > 0 && (
                <>
                  <details>
                    <summary>Post Set Fields</summary>
                    <div
                      className="d-flex flex-column justify-content-start align-items-start gap-2"
                      style={{ width: "100%" }}
                    >
                      {shapeData.postCondition.set.map((setField, index) => (
                        <div
                          key={`${setField.field}_${index}_${setField.value}`}
                          className="d-flex justify-content-between align-items-start ms-1"
                          style={{ width: "100%" }}
                        >
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            {index + 1}
                            {"."}
                          </span>
                          <div
                            className="d-flex flex-column justify-content-start align-items-start gap-1"
                            style={{ width: "80%" }}
                          >
                            <div className="d-flex justify-content-start align-items-start gap-2">
                              {" "}
                              <span style={{ fontSize: "14px" }}>
                                {setField.field}
                              </span>
                              <span style={{ fontSize: "14px" }}>=&gt;</span>
                              <span style={{ fontSize: "14px" }}>
                                {setField.value}
                              </span>
                            </div>
                            <span
                              style={{
                                width: "90%",
                                wordBreak: "break-all",
                                wordWrap: "break-word",
                                lineBreak: "anywhere",
                                lineClamp: "3",
                                fontSize: "12px",
                              }}
                            >
                              Condition:{" "}
                              {JSON.stringify(setField.condition, null, 2)}
                            </span>
                          </div>
                          <button
                            onClick={(e) => handlePostSetFieldRemove(e, index)}
                            className="condition_delete_button"
                          >
                            <MdDelete />
                          </button>
                        </div>
                      ))}
                    </div>
                  </details>
                </>
              )}
            {showInputFields.postSet ? (
              <>
                <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                  <label htmlFor="">Add set field</label>
                  <div className="d-flex justify-content-start align-items-start gap-2">
                    <select
                      name="field"
                      onChange={handleFieldSetUnsetChange}
                      value={fieldSetUnset.field}
                    >
                      <option>Select Field</option>
                      {formFields &&
                        formFields.map((field) => (
                          <option
                            key={field.technicalName}
                            value={field.technicalName}
                          >
                            {field.title}
                          </option>
                        ))}
                    </select>
                    <input
                      type="text"
                      placeholder="select value"
                      name="value"
                      value={fieldSetUnset.value}
                      onChange={handleFieldSetUnsetChange}
                    />
                  </div>
                  {fieldSetUnset.condition === "" ||
                  fieldSetUnset.condition === null ||
                  fieldSetUnset.condition === undefined ? (
                    <DecisionRule
                      form={shapeData.form.formbuilder_data}
                      saveRuleData={handleFieldSetUnsetChange}
                      ruleName="condition"
                    />
                  ) : (
                    <div
                      style={{ width: "90%" }}
                      className="ms-3 d-flex justify-content-between align-items-center"
                    >
                      <p className="mb-0">
                        <span>
                          <TbPointFilled />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            wordBreak: "break-all",
                            lineBreak: "anywhere",
                            wordWrap: "break-word",
                          }}
                        >
                          {fieldSetUnset.condition}
                        </span>
                      </p>
                      <button
                        className="fdu_group_delete"
                        onClick={handleFieldSetUnsetConditionDelete}
                      >
                        <GiCancel />
                      </button>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-start align-items-start gap-2">
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={handlePostSetAdd}
                    style={{ fontSize: "14px" }}
                  >
                    Add Field
                  </button>
                  <button
                    className="btn btn-secondary mt-2"
                    style={{ fontSize: "14px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowInputFields({
                        ...showInputFields,
                        postSet: false,
                      });
                    }}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : Array.isArray(shapeData.postCondition?.set) &&
              shapeData.postCondition?.set?.length > 0 ? (
              <div>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInputFields({ ...showInputFields, postSet: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Add Set Field
                </button>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-start align-items-start gap-1">
                <li style={{ fontSize: "14px" }}>
                  There is not any post set fields.
                </li>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInputFields({ ...showInputFields, postSet: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Add Post Set field
                </button>
              </div>
            )}
          </div>

          {/* code for post unset fields */}
          <div style={{ width: "80%" }}>
            {shapeData.postCondition?.unset &&
              shapeData.postCondition?.unset?.length > 0 && (
                <>
                  <details>
                    <summary>Post Unset Fields</summary>
                    <div
                      className="d-flex flex-column justify-content-start align-items-start gap-2"
                      style={{ width: "100%" }}
                    >
                      {shapeData.postCondition.unset.map(
                        (unsetField, index) => (
                          <div
                            key={`${unsetField.field}_${index}_${unsetField.value}`}
                            className="d-flex justify-content-between align-items-start ms-1"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              {index + 1}
                              {"."}
                            </span>
                            <div
                              className="d-flex flex-column justify-content-start align-items-start gap-1"
                              style={{ width: "80%" }}
                            >
                              <div className="d-flex justify-content-start align-items-start gap-2">
                                {" "}
                                <span style={{ fontSize: "14px" }}>
                                  {unsetField.field}
                                </span>
                                <span style={{ fontSize: "14px" }}>=&gt;</span>
                                <span style={{ fontSize: "14px" }}>
                                  {unsetField.value}
                                </span>
                              </div>
                              <span
                                style={{
                                  width: "90%",
                                  wordBreak: "break-all",
                                  wordWrap: "break-word",
                                  lineBreak: "anywhere",
                                  lineClamp: "3",
                                  fontSize: "12px",
                                }}
                              >
                                Condition:{" "}
                                {JSON.stringify(unsetField.condition, null, 2)}
                              </span>
                            </div>
                            <button
                              onClick={(e) =>
                                handlePostUnsetFieldRemove(e, index)
                              }
                              className="condition_delete_button"
                            >
                              <MdDelete />
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  </details>
                </>
              )}
            {showInputFields.postUnset ? (
              <>
                <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                  <label htmlFor="">Add unset field</label>
                  <div className="d-flex justify-content-start align-items-start gap-2">
                    <select
                      name="field"
                      onChange={handleFieldSetUnsetChange}
                      value={fieldSetUnset.field}
                    >
                      <option>Select Field</option>
                      {formFields &&
                        formFields.map((field) => (
                          <option
                            key={field.technicalName}
                            value={field.technicalName}
                          >
                            {field.title}
                          </option>
                        ))}
                    </select>
                    <input
                      type="text"
                      placeholder="select value"
                      name="value"
                      value={fieldSetUnset.value}
                      onChange={handleFieldSetUnsetChange}
                    />
                  </div>
                  {fieldSetUnset.condition === "" ||
                  fieldSetUnset.condition === null ||
                  fieldSetUnset.condition === undefined ? (
                    <DecisionRule
                      form={shapeData.form.formbuilder_data}
                      saveRuleData={handleFieldSetUnsetChange}
                      ruleName="condition"
                    />
                  ) : (
                    <div
                      style={{ width: "90%" }}
                      className="ms-3 d-flex justify-content-between align-items-center"
                    >
                      <p className="mb-0">
                        <span>
                          <TbPointFilled />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            wordBreak: "break-all",
                            lineBreak: "anywhere",
                            wordWrap: "break-word",
                          }}
                        >
                          {fieldSetUnset.condition}
                        </span>
                      </p>
                      <button
                        className="fdu_group_delete"
                        onClick={handleFieldSetUnsetConditionDelete}
                      >
                        <GiCancel />
                      </button>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-start align-items-start gap-2">
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={handlePostUnsetAdd}
                    style={{ fontSize: "14px" }}
                  >
                    Add Field
                  </button>
                  <button
                    className="btn btn-secondary mt-2"
                    style={{ fontSize: "14px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowInputFields({
                        ...showInputFields,
                        postUnset: false,
                      });
                    }}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : Array.isArray(shapeData.postCondition?.unset) &&
              shapeData.postCondition?.unset?.length > 0 ? (
              <div>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInputFields({ ...showInputFields, postUnset: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Add Unset Field
                </button>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-start align-items-start gap-1">
                <li style={{ fontSize: "14px" }}>
                  There is not any post unset fields.
                </li>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInputFields({ ...showInputFields, postUnset: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Add Post Unset field
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DefaultTab;
