import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import "./EmailTemplateOverlay.css";
import { useState } from "react";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import EmailEditor from "../../utility_files/EmailEditor";
import { useFormContext } from "../../context/FormContext";
import { useNodeContext } from "../../context/NodeContext";
import { useProcessContext } from "../../context/ProcessContext";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import CustomDropdown from "../custom_dropdown/CustomDropdown";

const EmailTemplateOverlay = () => {
  const { parseFormData, forms, setForms, fetchForms } = useFormContext();
  const { setNodes, shapeId } = useNodeContext();
  const {
    userData,
    newProcessId,
    centerAlert,
    emailTemplateId,
    isEmailTemplateModalOpen,
    setEmailTemplateModalOpen,
    isUpdateEmailTemplate,
    setUpdateEmailTemplate,
    fetchAllEmailTemplates,
    setEmailTemplateId,
    encryptData,
    fetchAllActiveDatabase,
  } = useProcessContext();
  const [emailConfigStep, setEmailConfigStep] = useState("emailConfigForm");
  const [templateData, setTemplateData] = useState({
    database: "",
    template_name: "",
    mail_trigger: "",
    mail_limit: "",
    mail_to: "",
    mail_from: "",
    mail_subject: "",
    mail_body: "",
    attachment_file: [],
  });
  const [selectedModel, setSelectedModel] = useState(null);
  const [odooModelsByDbId, setOdooModelsByDbId] = useState(null);
  const [fieldsSelectedModel, setFieldsSelectedModel] = useState(null);
  // const [prevStepFormFields, setPrevStepFormFields] = useState(null);
  const [emailFormsField, setEmailFormsField] = useState(null);
  const [activeDataBases, setActiveDatabases] = useState(null);
  // method for getting active database
  const getData = async () => {
    const activeDatabases = await fetchAllActiveDatabase();
    // console.log("Active Databases:", activeDatabases);
    setActiveDatabases(activeDatabases);
  };

  // fetching active database
  useEffect(() => {
    getData();
  }, []);
  // useEffect for getting form field
  // useEffect(() => {
  //   const currentNode = nodes.find((node) => node.id === shapeId);
  //   const prevNode = nodes.find((node) => node.id === currentNode?.data.prev);
  //   if (prevNode !== null && prevNode !== undefined && prevNode !== "") {
  //     if (prevNode.data.form && prevNode.data.isFormSelected) {
  //       const formFieldData = prevNode.data.form.formbuilder_data;
  //       const allFieldsData = parseFormData(formFieldData);
  //       if (allFieldsData) {
  //         setPrevStepFormFields([...allFieldsData]);
  //       } else {
  //         setPrevStepFormFields(null);
  //       }
  //     }
  //   }
  // }, [shapeId]);
  // useEffect for getting all forms field
  // useEffect for all fields
  const uniqueByTechnicalName = (array) => {
    const map = new Map();
    array.forEach((item) => {
      if (!map.has(item.technicalName)) {
        map.set(item.technicalName, item);
      }
    });
    return Array.from(map.values());
  };
  useEffect(() => {
    if (newProcessId !== null && newProcessId !== "") {
      fetchForms();
      if (forms) {
        const oprs = [];
        forms.forEach((form) => {
          if (form.formbuilder_data) {
            const opr = parseFormData(form.formbuilder_data);
            opr.forEach((op) => {
              oprs.push(op);
            });
          }
        });
        const uniqueFields = uniqueByTechnicalName(oprs);
        setEmailFormsField(uniqueFields);
      }
    } else {
      setEmailFormsField(null);
    }
  }, [setForms, newProcessId]);

  // const fetchEmailTemplateByTemplateId = async () => {
  //   try {
  //     const response = await fetch(`${BASE_URL}/email/fetch`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         user_id: userData.userid,
  //         process_id: newProcessId,
  //         key: API_KEY,
  //       }),
  //     });

  //     const data = await response.json();
  //     if (response.ok) {
  //       console.log("email template::: data::: ", data);
  //     } else {
  //       // centerAlert("error", data.message);
  //       console.error("Error creating new email template:", data);
  //     }
  //   } catch (error) {
  //     // centerAlert("error", error);
  //     console.error("Error in email create:", error);
  //   }
  // };
  const fetchOneEmail = async () => {
    try {
      const payload = {
        email_id: emailTemplateId,
        user_id: userData.userid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/email/fetchone`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
        // body: JSON.stringify({
        //   email_id: emailTemplateId,
        //   user_id: userData.userid,
        //   key: API_KEY,
        // }),
      });

      if (response.ok) {
        const data = await response.json();
        setTemplateData((prevData) => ({
          ...prevData,
          database: data.email.database || "",
          template_id: data.email._id || "",
          template_name: data.email.template_name || "",
          mail_trigger: data.email.mail_trigger || "",
          mail_limit: data.email.mail_limit || "",
          mail_to: data.email.mail_to || "",
          mail_from: data.email.mail_from || "",
          mail_subject: data.email.mail_subject || "",
          mail_body: data.email.mail_body || "",
          attachment_file: [],
        }));
      } else {
        const data = await response.json();
        console.error("Error fetching email :", data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (emailTemplateId) {
      fetchOneEmail();
    }

    //  else {
    //   const currentNode = nodes.find((node) => node.id === shapeId);
    //   if (currentNode?.data?.template) {
    //     const template = currentNode.data.template;
    //     console.log("template:::: ", template);
    //     setTemplateData((prevData) => ({
    //       ...prevData,
    //       template_id: template.template_id || "",
    //       template_name: template.template_name || "",
    //       mail_trigger: template.mail_trigger || "",
    //       mail_limit: template.mail_limit || "",
    //       mail_to: template.mail_to || "",
    //       mail_from: template.mail_from || "",
    //       mail_subject: template.mail_subject || "",
    //       mail_body: template.mail_body || "",
    //       attachment_file: [],
    //     }));
    //   }
    // }

    return () => handleReset();
  }, [shapeId, emailTemplateId]);

  // useEffect for fetching all models in odoo
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/odoo/models/database?database_id=${templateData.database}&api_key=${API_KEY}`
        );
        const data = await response.json();
        if (response.ok) {
          if (Array.isArray(data.data)) {
            const modelsData = data.data;
            const acceptedModel = modelsData.filter(
              (item) =>
                item.model.startsWith("o2b") || item.model.startsWith("x_o2b_")
            );
            setOdooModelsByDbId(acceptedModel);
            // setOdooModelsByDbId(data.data);
          }
        } else {
          console.error("Error in fetch: ", data);
        }
      } catch (error) {
        console.log("server error: ", error);
      }
    };
    if (templateData.database) {
      fetchModels();
    }
  }, [templateData, templateData.database]);

  const handleModelChange = (e) => {
    const { value } = e.target;
    setSelectedModel(value);
  };

  // useEffect for fetching all fields selected modal
  useEffect(() => {
    if (selectedModel !== "") {
      const fetchModalFields = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/odoo/fields/database?model=${selectedModel}&database_id=${templateData.database}&api_key=${API_KEY}`
          );
          const data = await response.json();
          if (response.ok) {
            if (Array.isArray(data.data)) {
              // console.log("related field data:::::::: ", data);
              // console.log("related fields:::::: ", JSON.stringify(data.data));
              setFieldsSelectedModel(data.data);
            }
          } else {
            console.error("Error in fetch: ", data);
          }
        } catch (error) {
          console.log("server error: ", error);
        }
      };
      fetchModalFields();
    }
  }, [templateData.database, selectedModel]);

  // method for template data change
  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setTemplateData((prevData) => ({ ...prevData, [name]: value }));
  };
  // method for selecting delay time
  const handleTimeChange = (e) => {
    setTemplateData((prevData) => ({
      ...prevData,
      mail_trigger: e.target.value,
    }));
  };
  // method for mail body change
  const handleContentChange = (content) => {
    setTemplateData((prevData) => ({ ...prevData, mail_body: content }));
    console.log("Latest Email Content:", content); // For debugging purposes
  };
  // method for attachment file change
  const handleAttachmentChange = (attachments) => {
    setTemplateData((prevData) => ({
      ...prevData,
      attachment_file: attachments,
    }));
  };
  // method for back button
  const handleBackConfigForm = (e) => {
    e.preventDefault();
    setEmailConfigStep("emailConfigForm");
  };
  // method for reset field
  const handleReset = () =>
    setTemplateData({
      database: "",
      template_name: "",
      mail_trigger: "",
      mail_limit: "",
      mail_to: "",
      mail_from: "",
      mail_subject: "",
      mail_body: "",
      attachment_file: [],
    });

  // method for save modal data
  const handleSubmit = async (e) => {
    e.preventDefault();
    switch (emailConfigStep) {
      case "emailConfigForm":
        if (
          templateData.database === "" ||
          templateData.template_name === null ||
          templateData.database === undefined
        ) {
          centerAlert("error", "Hutch field is mandatory to fill.");
          return;
        }
        setEmailConfigStep("emailTemplateForm");
        break;
      case "emailTemplateForm":
        try {
          // Create a FormData object to handle file uploads
          const formData = new FormData();
          formData.append("process_id", newProcessId);
          formData.append("user_id", userData.userid);
          formData.append("key", API_KEY);

          // Append other fields from templateData to formData
          Object.entries(templateData).forEach(([key, value]) => {
            if (key === "attachment_file" && value.length > 0) {
              value.forEach((file) => formData.append("attachment_file", file));
            } else {
              formData.append(key, value);
            }
          });

          console.log("email temp::: formData::: ", formData);
          const response = await fetch(`${BASE_URL}/email/create`, {
            method: "POST",
            body: formData,
          });

          const data = await response.json();
          if (response.ok) {
            console.log("email template::: data::: ", data);
            handleTemplateDataSaveOnNode(data.emailTemplate);
            centerAlert("success", data.message);
            handleReset();
            fetchAllEmailTemplates();
            const modalElement = document.getElementById("emailTemplateModal");
            const modalInstance =
              bootstrapBundleMin.Modal.getInstance(modalElement);
            modalInstance.hide();
            setEmailTemplateId(null);
            setUpdateEmailTemplate(false);
            setEmailTemplateModalOpen(false);
            setEmailConfigStep("emailConfigForm");
          } else {
            centerAlert("error", data.message);
            console.error("Error creating new email template:", data);
          }
        } catch (error) {
          centerAlert("error", error);
          console.error("Error in email create:", error);
        }
        break;

      default:
        console.log("error in steps");
        break;
    }
  };
  // method for updtae the email template
  const handleUpdateEmailTemplate = async (e) => {
    e.preventDefault();
    switch (emailConfigStep) {
      case "emailConfigForm":
        if (
          templateData.database === "" ||
          templateData.template_name === null ||
          templateData.database === undefined
        ) {
          centerAlert("error", "Hutch field is mandatory to fill.");
          return;
        }
        setEmailConfigStep("emailTemplateForm");
        break;
      case "emailTemplateForm":
        try {
          // Create a FormData object to handle file uploads
          const formData = new FormData();
          formData.append("process_id", newProcessId);
          formData.append("user_id", userData.userid);
          formData.append("key", API_KEY);
          formData.append("email_id", emailTemplateId);

          // Append other fields from templateData to formData
          Object.entries(templateData).forEach(([key, value]) => {
            if (key === "attachment_file" && value.length > 0) {
              value.forEach((file) => formData.append("attachment_file", file));
            } else {
              formData.append(key, value);
            }
          });

          console.log("email temp::: formData::: ", formData);
          const response = await fetch(`${BASE_URL}/email/update`, {
            method: "POST",
            body: formData,
          });

          const data = await response.json();
          if (response.ok) {
            console.log("email template::: data::: ", data);
            handleTemplateDataSaveOnNode(data.emailTemplate);
            centerAlert("success", data.message);
            handleReset();
            fetchAllEmailTemplates();
            setEmailTemplateId(null);
            setUpdateEmailTemplate(false);
            setEmailTemplateModalOpen(false);
            setEmailConfigStep("emailConfigForm");
          } else {
            centerAlert("error", data.message);
            console.error("Error creating new email template:", data);
          }
        } catch (error) {
          centerAlert("error", error);
          console.error("Error in email create:", error);
        }
        break;

      default:
        console.log("error in steps");
        break;
    }
  };
  // method for template data save
  const handleTemplateDataSaveOnNode = (emailTemplate) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                template: {
                  database: emailTemplate.database,
                  template_id: emailTemplate._id,
                  template_name: emailTemplate.template_name,
                  mail_trigger: emailTemplate.mail_trigger,
                  mail_limit: emailTemplate.mail_limit,
                  mail_to: emailTemplate.mail_to,
                  mail_from: emailTemplate.mail_from,
                  mail_subject: emailTemplate.mail_subject,
                  mail_body: emailTemplate.mail_body,
                  attachment_file: [],
                  // attachment_file: emailTemplate.attachment_file,
                },
              },
            }
          : node
      )
    );
  };

  console.log("email temp:::: template Data:::: ", templateData);
  console.log("email temp:::: fieldsSelectedModel:::: ", fieldsSelectedModel);
  return (
    <div
      className={`modal fade email_template_modal ${
        isEmailTemplateModalOpen ? "show" : ""
      }`}
      style={{ display: isEmailTemplateModalOpen ? "block" : "none" }}
      id="emailTemplateModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="emailTemplateModalLabel"
      aria-hidden={!isEmailTemplateModalOpen}
      aria-modal={isEmailTemplateModalOpen}
      role={isEmailTemplateModalOpen ? "dialog" : "none"}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content email_template_modal_content">
          <div className="modal-header email_template_modal_header">
            <h1 className="modal-title fs-5" id="emailTemplateModalLabel">
              Email Template
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              onClick={() => {
                handleReset();
                setEmailTemplateModalOpen(false);
                setEmailConfigStep("emailConfigForm");
              }}
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form
            method="post"
            onSubmit={
              isUpdateEmailTemplate ? handleUpdateEmailTemplate : handleSubmit
            }
            className="email_template_create_modal"
          >
            <div className="modal-body email_template_modal_body">
              <div className="emailTemplateModalForm">
                {emailConfigStep === "emailConfigForm" && (
                  <>
                    <div className="emailTemplateModalForm_inputField">
                      <label htmlFor="database">Hutch (Database)*</label>
                      <CustomDropdown
                        name="database"
                        defaultValue={templateData.database || null}
                        options={activeDataBases?.map((db) => {
                          return {
                            value: db._id,
                            label: `${db.configname} (${db.database})`,
                          };
                        })}
                        onChange={(event) => handleDataChange(event)}
                        width={"100%"}
                        height={"30px"}
                        border="1px solid gray"
                      />
                    </div>
                    <div className="emailTemplateModalForm_inputField">
                      <label htmlFor="template_name">Template Name</label>
                      <input
                        type="text"
                        id="template_name"
                        placeholder="Enter template name"
                        name="template_name"
                        value={templateData.template_name}
                        onChange={handleDataChange}
                        required
                      />
                    </div>
                    <div className="emailTemplateModalForm_inputField">
                      <label htmlFor="mail_trigger">
                        Duration to Start Step
                      </label>
                      <div className="d-flex flex-column justify-content-center align-items-start gap-1">
                        <div className="d-flex justify-content-start align-items-center gap-2 ms-1">
                          <input
                            type="radio"
                            id="mail_trigger"
                            name="mail_trigger"
                            value="0"
                            checked={templateData.mail_trigger === "0"}
                            onChange={handleDataChange}
                            required
                            style={{ width: "13px" }}
                          />
                          <label htmlFor="mail_trigger">Immediately</label>
                        </div>
                        <div className="d-flex justify-content-start align-items-start gap-2 ms-1">
                          <input
                            type="radio"
                            id="mail_trigger_custom"
                            name="mail_trigger"
                            value={
                              templateData.mail_trigger >= 1
                                ? templateData.mail_trigger
                                : "1"
                            }
                            checked={templateData.mail_trigger >= 1}
                            onChange={handleDataChange}
                            required
                            style={{ width: "13px" }}
                          />
                          <input
                            type="number"
                            min={1}
                            onChange={handleTimeChange}
                            value={
                              templateData.mail_trigger >= 1
                                ? templateData.mail_trigger
                                : 1
                            }
                            style={{ width: "100px", height: "30px" }}
                          />
                          <label htmlFor="mail_trigger_custom">
                            {"(in minutes) after"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="emailTemplateModalForm_inputField">
                      <label htmlFor="mail_limit">
                        Limit email count per 24 hours {"(Optional)"}
                      </label>
                      <input
                        type="number"
                        id="mail_limit"
                        placeholder="Enter form detail"
                        name="mail_limit"
                        min={1}
                        value={templateData.mail_limit}
                        onChange={handleDataChange}
                      />
                    </div>
                  </>
                )}
                {emailConfigStep === "emailTemplateForm" && (
                  <>
                    {/* <div className="emailTemplateModalForm_inputField">
                      <label htmlFor="mail_from">From</label>
                      <input
                        type="text"
                        id="mail_from"
                        placeholder="Enter sender email"
                        name="mail_from"
                        value={templateData.mail_from}
                        onChange={handleDataChange}
                        required
                      />
                    </div>
                    <div className="emailTemplateModalForm_inputField">
                      <label htmlFor="mail_to">To</label>
                      <input
                        type="text"
                        id="mail_to"
                        placeholder="Enter recipient email"
                        name="mail_to"
                        value={templateData.mail_to}
                        onChange={handleDataChange}
                        required
                      />
                    </div> */}
                    <div className="emailTemplateModalForm_inputField">
                      <label htmlFor="mail_subject">Subject</label>
                      <input
                        type="text"
                        id="mail_subject"
                        placeholder="Enter recipient email"
                        name="mail_subject"
                        value={templateData.mail_subject}
                        onChange={handleDataChange}
                        required
                      />
                    </div>
                    <div className="emailTemplateModalForm_inputField">
                      <label htmlFor="mail_from">Content</label>
                      <EmailEditor
                        onContentChange={handleContentChange}
                        attachments={templateData.attachment_file}
                        setAttachments={handleAttachmentChange}
                        models={odooModelsByDbId}
                        handleModelChange={handleModelChange}
                        dynamicVariable={
                          newProcessId === null
                            ? fieldsSelectedModel
                            : emailFormsField
                        }
                        // dynamicVariable={prevStepFormFields}
                        initialVal={templateData.mail_body || ""}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer email_template_modal_footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleReset();
                  setEmailTemplateModalOpen(false);
                  setEmailConfigStep("emailConfigForm");
                }}
              >
                Close
              </button>
              {emailConfigStep === "emailTemplateForm" && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleBackConfigForm}
                  // data-bs-dismiss="modal"
                >
                  Back
                </button>
              )}
              <button type="submit" className="btn modal_save_button">
                {/* <button onClick={handleSubmit} className="btn modal_save_button"> */}
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateOverlay;
