import React from "react";
import "./DownloadButton.css";
import { useProcessContext } from "../context/ProcessContext";
import { FILE_SECRET } from "../../config";

const ExportButton = ({ flowData }) => {
  const { processDetail, encryptData } = useProcessContext();
  const handleExport = (e) => {
    e.stopPropagation();
    const dataStr = JSON.stringify(processDetail, null, 2);
    const encryptedData = encryptData(dataStr, FILE_SECRET);
    const blob = new Blob([encryptedData], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${processDetail?.process_name
      .toLowerCase()
      .trim()
      .replace(" ", "_")}.oflow`; // Desired file name and extension
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
  };

  return (
    <button className="download-btn export-btn" onClick={handleExport}>
      Export Flow
    </button>
  );
};

export default ExportButton;
