import React from 'react'
import './LogOut.css'
import { useProcessContext } from '../../context/ProcessContext'
import { useNavigate } from 'react-router-dom'
import { useNodeContext } from '../../context/NodeContext'
import { useFormContext } from '../../context/FormContext'

const LogOut = () => {
    const { logout, setLogoutClicked } = useProcessContext();
    const { setShapeId } = useNodeContext()
    const { setForms, setFormData } = useFormContext()
    const navigate = useNavigate();

    const handleLogout = () => {
      setShapeId(null)
      setForms([])
      setFormData([])
      logout();
      setLogoutClicked(false);
      navigate("/");
    };
  return (
    <div className="logout">
      <span className="logout_quit_btn" onClick={() => setLogoutClicked(false)}>
        X
      </span>
      <button className="logout_button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
}

export default LogOut
