import React, { useEffect, useState } from "react";
import { useFormContext } from "../context/FormContext";
import { useNodeContext } from "../context/NodeContext";
import { useProcessContext } from "../context/ProcessContext";

const ExportFormFieldJson = ({ sltdForm }) => {
  const { centerAlert, newProcessId, processDetail } = useProcessContext();
  const { parseFormData } = useFormContext();
  const { shapeId, nodes } = useNodeContext();
  // Function to generate the new JSON and trigger download
  const [fieldData, setFieldData] = useState(null);
  const [processObjData, setProcessObjData] = useState({
    process_id: "",
    process_name: "",
    process_stage: "",
    process_stage_id: "",
  });
  useEffect(() => {
    if (sltdForm.formbuilder_data) {
      const formData = parseFormData(sltdForm.formbuilder_data);
      setFieldData([...formData]);
    }
  }, [shapeId, nodes]);
  //   useEffect getting latest process data
  useEffect(() => {
    if (newProcessId) {
      setProcessObjData((prevData) => ({
        ...prevData,
        process_id: newProcessId || "",
      }));
    }
    if (processDetail && processDetail.process_name) {
      setProcessObjData((prevData) => ({
        ...prevData,
        process_name: processDetail?.process_name || "",
      }));
    }
    if (shapeId && nodes) {
      const currentNode = nodes.find((node) => node.id === shapeId);

      setProcessObjData((prevData) => ({
        ...prevData,
        process_stage: currentNode?.data?.value ? currentNode?.data?.value : "",
        process_stage_id: shapeId || "",
      }));
    }
  }, [newProcessId, shapeId]);

  const handleDownload = (e) => {
    e.preventDefault();
    // Create the new JSON object with technicalName keys and blank values
    if (fieldData) {
      const jsonData = fieldData?.reduce((acc, obj) => {
        acc[obj.technicalName] = ""; // Set blank value
        return acc;
      }, {});

      const newExportableData = {
        processDetail: { ...processObjData },
        fields: { ...jsonData },
      };

      // Convert JSON object to a string for downloading
      //   const jsonString = JSON.stringify(jsonData, null, 2);
      const jsonString = JSON.stringify(newExportableData, null, 2);

      // Create a blob and a link to download the file
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement("a");
      a.href = url;
      a.download = "form_data.json"; // File name
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    } else {
      centerAlert("error", "Assign Form first to this activity.");
    }
  };

  console.log("fieldData::::::::::::::::::::::: ", fieldData);
  return (
    <button className="docType_create_btn mt-2" onClick={handleDownload}>
      Export Form Json
    </button>
  );
};

export default ExportFormFieldJson;
