import React, { useEffect, useState } from "react";
import "./ProcessOverlay.css";
import BASE_URL from "../../../config";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useProcessContext } from "../../context/ProcessContext";
import { useNodeContext } from "../../context/NodeContext";
import Swal from "sweetalert2";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { IoMdClose } from "react-icons/io";
import CustomDropdown from "../custom_dropdown/CustomDropdown";

const ProcessOverlay = () => {
  const {
    userData,
    setProcessDetail,
    setNewProcessId,
    centerAlert,
    centerAlertWithTitle,
    groups,
    fetchAllActiveDatabase,
    fetchAllGroups,
  } = useProcessContext();
  const { setNodes, setEdges, initialNodes } = useNodeContext();
  const navigate = useNavigate();
  const [activeDataBases, setActiveDatabases] = useState(null);
  const [processFormData, setProcessesFormData] = useState({
    process_database: null,
    process_group: null,
    process_name: "",
    process_detail: "",
    user_id: userData.userid,
    process_docTypes: null,
    process_toDos: null,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filteredGroup, setFilteredGroup] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDataChange = (e) => {
    // const { name } = e.target;
    // let newValue = e.target.value;
    if (e.target.name === "process_name") {
      const regex = /^[a-zA-Z0-9_ ]*$/;

      if (regex.test(e.target.value)) {
        // newValue = e.target.value
        setProcessesFormData({
          ...processFormData,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setProcessesFormData({
        ...processFormData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // method for getting active database
  const getData = async () => {
    const activeDatabases = await fetchAllActiveDatabase();
    console.log("Active Databases:", activeDatabases);
    setActiveDatabases(activeDatabases);
  };

  // fetching active database
  useEffect(() => {
    getData();
  }, []);

  // fetching all groups available in specific database
  useEffect(() => {
    if (processFormData.process_database) {
      if (groups.length > 0) {
        const grps = groups.filter(
          (group) => group.database_obj === processFormData.process_database
        );
        setFilteredGroup(grps);
      } else {
        fetchAllGroups();
      }
    }
  }, [processFormData.process_database, groups]);

  const handleReset = () =>
    setProcessesFormData({
      ...processFormData,
      process_database: "",
      process_group: null,
      process_name: "",
      process_detail: "",
    });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      processFormData.process_database === "" ||
      processFormData.process_database === null
    ) {
      return centerAlert("error", "Hutch is mandatory to select.");
    }
    const formData = new FormData();

    // Append all the data to FormData
    Object.keys(processFormData).forEach((key) => {
      formData.append(key, processFormData[key]);
    });
    formData.append("process_icon", selectedFile);
    try {
      const response = await fetch(`${BASE_URL}/process/create`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        console.log("process create data: ", data);
        setNewProcessId(data.process.process_id);
        console.log("process data at creation:::", data.process);
        setNodes(initialNodes);
        setEdges([]);
        setProcessDetail({ ...data.process });
        handleReset();
        centerAlertWithTitle(
          "Process Initiated Successfull!",
          "Please Design Process with Controll Panel.",
          "success"
        );
        const modalElement = document.getElementById("exampleModal");
        const modalInstance =
          bootstrapBundleMin.Modal.getInstance(modalElement);
        modalInstance.hide();
        navigate("/process-modeler");
      } else if (response.status === 409) {
        console.log("process create data: ", data);
        centerAlertWithTitle("Data Already Exist.", data.message, "error");
      } else {
        console.log("process create data: ", data);
        centerAlert("error", data.message);
      }
    } catch (error) {
      centerAlertWithTitle("Oops...", error, "error");
    }
    // console.log("processFormData: ", processFormData);
    // console.log("FormData: ", formData);
  };

  // const handleAiFill = async () => {
  //   setLoading(true);
  //   const prompt = `Generate a detailed description of the process model for the process named "${processFormData.process_name}".`;
  //   try {
  //     const generatedText = await generateText(prompt);
  //     const usefulSentence = generatedText.trim().split(".")[1].trim() + ".";
  //     if (processFormData.process_detail !== usefulSentence.trim()) {
  //       setProcessesFormData({
  //         ...processFormData,
  //         process_detail: usefulSentence.trim(),
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error generating text:", error);
  //   }
  //   setLoading(false);
  // };

  console.log("groups::: ", groups);
  console.log("processFormData::: ", processFormData);
  // console.log("selectedFile::: ", selectedFile);
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              New Process Form
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="processModalForm">
                <div className="processModalForm_inputField">
                  <label htmlFor="process_database">Hutch (database)*</label>
                  <CustomDropdown
                    name="process_database"
                    defaultValue={processFormData.process_database || null}
                    options={activeDataBases?.map((db) => {
                      return {
                        value: db._id,
                        label: `${db.configname} (${db.database})`,
                      };
                    })}
                    onChange={(event) => handleDataChange(event)}
                    width={"95%"}
                    height={"35px"}
                    border="1px solid gray"
                  />
                </div>
                <div className="processModalForm_inputField">
                  <label htmlFor="process_group">Group Access</label>
                  <CustomDropdown
                    name="process_group"
                    defaultValue={processFormData.process_group || null}
                    options={filteredGroup?.map((group) => {
                      return {
                        value: `base.${group.group_internal}`,
                        label: group.group_name,
                      };
                    })}
                    onChange={(event) => handleDataChange(event)}
                    width={"95%"}
                    height={"35px"}
                    border="1px solid gray"
                  />
                </div>
                <div className="processModalForm_inputField">
                  <label htmlFor="process_name">Process Name*</label>
                  <input
                    type="text"
                    id="process_name"
                    placeholder="Enter process name"
                    name="process_name"
                    value={processFormData.process_name}
                    onChange={handleDataChange}
                    required
                  />
                </div>
                <div className="processModalForm_inputField">
                  <label htmlFor="formFile">Process Icon</label>
                  <input
                    type="file"
                    id="formFile"
                    className="form-control"
                    placeholder="upload process icon"
                    name="process_icon"
                    onChange={handleFileChange}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="processModalForm_inputField">
                  <label htmlFor="process_detail">Process Detail</label>
                  <input
                    type="text"
                    id="process_detail"
                    placeholder="Enter process detail"
                    name="process_detail"
                    value={processFormData.process_detail}
                    onChange={handleDataChange}
                  />
                  {/* <button
                    type="button"
                    onClick={handleAiFill}
                    disabled={loading}
                  >
                    {loading ? (
                      <AiOutlineLoading3Quarters />
                    ) : (
                      <MdAutoFixHigh />
                    )}
                  </button> */}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn modal_save_button">
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProcessOverlay;
