import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";

const ForgetPassword = () => {
  const { centerAlert, topRightAlert, encryptData } = useProcessContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [submitClicked, setSubmitClicked] = useState(() => {
    const savedForgetClicked = sessionStorage.getItem("forgetClicked");
    return savedForgetClicked ? JSON.parse(savedForgetClicked) : false;
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        email: email,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/user/email/capture`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
        // body: JSON.stringify({
        //   email: email,
        //   key: API_KEY,
        // }),
      });

      if (response.ok) {
        const data = await response.json();
        topRightAlert("success", data.message);
        sessionStorage.setItem("pinemail", JSON.stringify(email));
        setSubmitClicked(true);
        setEmail("");
      } else {
        const data = await response.json();
        topRightAlert("error", data.message);
        console.error("Error updating process status:", data);
      }
    } catch (error) {
      topRightAlert("error", error);
      console.error("Error updating process status:", error);
    }
    // console.log("email::: ", email);
  };
  const handleOtpVerify = async (e) => {
    e.preventDefault();
    const emailid = JSON.parse(sessionStorage.getItem("pinemail"));
    try {
      const payload = {
        email: emailid,
        otp: otp,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/user/email/validate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
        // body: JSON.stringify({
        //   email: emailid,
        //   otp: otp,
        //   key: API_KEY,
        // }),
      });

      if (response.ok) {
        const data = await response.json();
        topRightAlert("success", data.message);
        setOtp("");
        setSubmitClicked(false);
        sessionStorage.setItem("pinemail", JSON.stringify(data.email));
        sessionStorage.removeItem("forgetClicked");
        navigate("/reset-password");
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error updating process status:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error updating process status:", error);
    }
  };
  // const handleLoginBack = (e) => {
  //   e.preventDefault();
  //   setSubmitClicked(false);
  //   sessionStorage.removeItem("forgetClicked");
  //   navigate("/");
  // };
  useEffect(() => {
    sessionStorage.setItem("forgetClicked", JSON.stringify(submitClicked));
  }, [submitClicked]);
  return (
    <div className="limiter">
      <div
        className="container-login100"
        // style={{ backgroundImage: 'url("bg-reg.jpg")' }}
      >
        <div className="wrap-login100 p-l-60 p-r-60 p-t-40 p-b-40">
          <span className="login100-form-title p-b-30">
            {" "}
            {submitClicked ? "Verify OTP" : "Reset Password"}
          </span>
          {submitClicked ? (
            <form
              className="login100-form validate-form flex-sb flex-w"
              // method="post"
              onSubmit={handleOtpVerify}
            >
              <span className="txt1 p-b-11"> Enter OTP </span>
              <div
                className="wrap-input100 validate-input"
                data-validate="Username is required"
              >
                <input
                  className="input100"
                  type="text"
                  name="otp"
                  value={otp}
                  maxLength={6}
                  minLength={6}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="enter otp here..."
                  required
                />
                <span className="focus-input100" />
              </div>
              <div className="input-st m-b-36">
                Please enter otp recieved on registered email address.
              </div>
              <div className="container-login100-form-btn">
                <button type="submit" className="login100-form-btn">
                  Verify Otp
                </button>
              </div>
            </form>
          ) : (
            <>
              <form
                className="login100-form validate-form flex-sb flex-w"
                // method="post"
                onSubmit={handleSubmit}
              >
                <span className="txt1 p-b-11"> Email address </span>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Username is required"
                >
                  <input
                    className="input100"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="enter your email here..."
                    required
                  />
                  <span className="focus-input100" />
                </div>
                <div className="input-st m-b-36">
                  Please enter your registered email address
                </div>
                <div className="container-login100-form-btn">
                  <button type="submit" className="login100-form-btn">
                    Send Otp
                  </button>
                </div>
                <div className="input-st p-t-20">
                  Return Back to{" "}
                  {/* <a href="/register" style={{ color: "#2c57ed" }}> */}
                  <Link to="/" style={{ color: "#007485" }}>
                    Login
                  </Link>
                </div>
              </form>
            </>
          )}
        </div>
        <div className="background-shape">
          <div className="shape" />
          <div className="shape" />
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
