import React from 'react'
import "./FormBuilderPage.css";
import FormControl from './form_builder_content/FormControl';
import FormBoard from './form_builder_content/FormBoard';
import FormDetailUpdate from './form_builder_content/FormDetailUpdate';
import { useFormContext } from '../../context/FormContext';

const FormBuilderPage = () => {
  const {isKanbanBuilder} = useFormContext()
  return (
    <div className="formbuilder">
      <div className="fb_container">
        <FormControl />
        <FormBoard />
        {!isKanbanBuilder && (<FormDetailUpdate />)}
      </div>
    </div>
  );
}

export default FormBuilderPage
