import React from "react";
import { FaDeleteLeft } from "react-icons/fa6";

const CreateInvoice = ({ handleRemoveAction }) => {
  return (
    <div className="cc_container">
      <div
        className="d-flex flex-column justify-content-center align-items-start gap-1"
        style={{ width: "100%" }}
      >
        <label htmlFor="">Create Invoice</label>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <button
            className="action_map_field_button"
            data-bs-toggle="modal"
            data-bs-target="#createInvoiceModal"
          >
            Map Fields
          </button>{" "}
          <button
            className="action_delete_button"
            onClick={() => handleRemoveAction("Create Invoice")}
          >
            <FaDeleteLeft style={{ fontSize: "20px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateInvoice;
