import React, { useEffect } from "react";
import "./DashBoard.css";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Groups from "./Groups";
import Processes from "./Processes";
import Configurations from "./Configurations";
import EmailConfigurationPage from "./EmailConfigurationPage";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Save the current dashboard route in session storage whenever it changes
  useEffect(() => {
    if (location.pathname.startsWith("/dashboard")) {
      sessionStorage.setItem("lastVisitedDashboardRoute", location.pathname);
    }
  }, [location]);

  // On component mount, redirect to the last visited dashboard route if it exists
  useEffect(() => {
    const lastVisitedRoute = sessionStorage.getItem(
      "lastVisitedDashboardRoute"
    );
    if (lastVisitedRoute && lastVisitedRoute !== "/dashboard") {
      navigate(lastVisitedRoute);
    }
  }, [navigate]);

  return (
    <div className="dashBoard">
      <div className="">
        <div className="donor_row">
          <div className="donor_dash_left">
            <div className="donor_dash_side">
              <div className="donor_side_menu">
                <ul className="donor_menu">
                  <li>
                    <Link
                      to="/dashboard/processes"
                      className={` ${
                        location.pathname === "/dashboard/processes"
                          ? "active"
                          : ""
                      }`}
                    >
                      {" "}
                      Processes{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dashboard/groups"
                      className={` ${
                        location.pathname === "/dashboard/groups"
                          ? "active"
                          : ""
                      }`}
                    >
                      {" "}
                      Groups{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dashboard/configurations"
                      className={` ${
                        location.pathname.startsWith(
                          "/dashboard/configurations"
                        )
                          ? // location.pathname === "/dashboard/configurations"
                            "active"
                          : ""
                      }`}
                    >
                      {" "}
                      Configurations{" "}
                    </Link>
                    {/* ========================= */}
                    {location.pathname.startsWith(
                      "/dashboard/configurations"
                    ) && (
                      <div className="donor_side_menu">
                        <ul className="donor_menu">
                          <li>
                            <Link
                              to="/dashboard/configurations/hutch"
                              className={` ${
                                location.pathname ===
                                "/dashboard/configurations/hutch"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {" "}
                              Hutch Configuration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/dashboard/configurations/email"
                              className={` ${
                                location.pathname ===
                                "/dashboard/configurations/email"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {" "}
                              Email Configuration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              // to="/dashboard/configurations/model"
                              className={` ${
                                location.pathname ===
                                "/dashboard/configurations/model"
                                  ? "active"
                                  : ""
                              }`}
                              style={{ cursor: "not-allowed" }}
                            >
                              {" "}
                              Model Configuration{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                    {/* ========================= */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard/processes" />} />
            <Route path="/processes" element={<Processes />} />
            {/* <Route path="/dashboard/configurations" element={<Navigate to="/dashboard/configurations/hutch" />} /> */}
            <Route path="/groups" element={<Groups />} />
            {/* ====================== */}
            {/* Redirect to /hutch by default if /dashboard/configurations is accessed */}
            <Route path="/configurations/*" element={<Outlet />}>
              <Route
                index
                element={<Navigate to="/dashboard/configurations/hutch" />}
              />
              <Route path="email" element={<EmailConfigurationPage />} />
              <Route path="model" element={<span>Hello Model</span>} />
              <Route path="hutch" element={<Configurations />} />
            </Route>
            {/* ====================== */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
